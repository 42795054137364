import React, { useEffect, useState, useRef } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import { toast } from 'react-toastify';
import apiService from '../../services/api';
import Modal from 'react-bootstrap/Modal';

let scanCanceled = false;

export const BatchScanModal = ({ 
  showDialog, 
  handleClose, 
}) => {
  const [isScanning, setIsScanning] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadingPercent, setUploadingPercent] = useState(0)
  const [result, setResult] = useState({});
  const showDialogRef = useRef()
  showDialogRef.current = showDialog;
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showAskContinue, setShowAskContinue] = useState(false)
  const fileNameListRef = useRef([])
  const scannedFileCountRef = useRef(0)
  const batchIDRef = useRef()

  useEffect(() => {
    if(showDialog) {
      initState()
      getNewBatchID()
      scan()
    }
  }, [showDialog])

  const initState = () => {
    setIsScanning(true)
    setIsUploading(false)
    setUploadingPercent(0)
    setResult({})
    setShowCancelDialog(false)
  }

  const scan = async () => {
    scanCanceled = false;
    window.electron.scan("duplex", localStorage.getItem("current_default_scanner"))
    window.electron.scanRes(async (event, data) => {
      if(scanCanceled) {
        return;
      }
      if(showDialogRef.current) {
        if(data.code === 0) {
          if(data.details) {
            setResult({msg: data.details})
          } else if (data.msg) {
            setResult({msg: data.msg})
          }
          if(data.data && data.data.fileNameList) {
            fileNameListRef.current = data.data.fileNameList;
            scannedFileCountRef.current = data.data.fileNameList.length; 
            setResult({msg: "Uploading. Please wait..."})
            setIsScanning(false)
            setIsUploading(true)
            uploadFile();
          }
        } else {
          setIsScanning(false)
          setIsUploading(false)
          if(data.details) {
            setResult({msg: data.details})
          } else if (data.msg) {
            setResult({msg: data.msg})
          }
        }
      }
    });
  }

  const uploadFile = async () => {
    if(fileNameListRef.current.length > 0) {
      let fileName = fileNameListRef.current[0]
      window.electron.readScanFile(fileName).then(async (data) => {
        let progress = 100 - (fileNameListRef.current.length * 100 / scannedFileCountRef.current)
        setUploadingPercent(progress)

        if(data.code !== 0) {
          setResult({msg: data.details});
          setTimeout(() => {
            uploadFile()
          }, 1000);
        } else {
          let uploadRes;
          try{
            const axiosRes = await apiService.uploadScanResult({
              file: data.data, 
              batchID: batchIDRef.current,
              // lastOneInABatch: fileNameListRef.current.length === 1, // no need to this for batch scan
            })
            uploadRes = axiosRes.data
          } catch (err) {
            console.error(err)
            uploadRes = {code: -1, msg: err.message}
          }
          if(uploadRes.code == 0) {
            window.electron.deleteScanFile(fileName)
            fileNameListRef.current = fileNameListRef.current.filter((item) =>  item !== fileName)
            setResult({msg: "Uploading. Please wait..."});
            uploadFile()
          } else {
            setResult({msg: "Error while uploading file. " + uploadRes.code + ". Retrying..."});
            setTimeout(() => {
              uploadFile()
            }, 2000);
          }
        }
      })
    } else {
      toast.success("Your documents uploaded successfully.", {autoClose: 7000});
      setShowAskContinue(true);
    }
  }

  const getNewBatchID = () => {
    batchIDRef.current = localStorage.getItem("lucas_therapies_logined_username") + "-" + new Date().getTime()
  }

  const handleCancel = async () => {
    scanCanceled = true;


    if(isScanning || isUploading) {
      setShowCancelDialog(true)
    } else {
      handleClose()
    }
  }

  const continueAnotherScan = () => {
    setShowAskContinue(false)
    initState();
    scan();
  }

  const hideCancelDialog = () => {
    setShowCancelDialog(false)
  }

  const hideContinueDialog = () => {
    setShowAskContinue(false)
  }

  const confirmCancel = async () => {
    try{
      toast.warning("Scan canceled!")

      await window.electron.cancelScan()
  
      hideCancelDialog(); 
      handleClose();
    } catch (err) {
      console.error(err)
      toast.error("Error while canceling scan. message: " + err.message, 10000)
    }
  }

  return (
    <div>
      <Modal show={showCancelDialog} size={'md'} onHide={hideCancelDialog} centered>
        <DialogTitle style={{ cursor: 'move' }} disableTypography={true}>
          <h5>Cancel</h5>
        </DialogTitle>
        <DialogContent>
          Are you sure to close? (If the upload is already started it won't stop)
        </DialogContent>
        <DialogActions className='px-4 pb-4'>
          <button className='btn btn-outline-secondary' onClick={hideCancelDialog}>
            No (continue)
          </button>
          <button className='btn btn-outline-primary' onClick={confirmCancel}>
            Yes (close)
          </button>
        </DialogActions>
      </Modal> 

      <Modal show={showAskContinue} size={'md'} onHide={hideContinueDialog} centered>
        <DialogTitle style={{ cursor: 'move' }} disableTypography={true}>
          <h5>Scan Another Document</h5>
        </DialogTitle>
        <DialogContent>
          Would you like to continue scanning?
        </DialogContent>
        <DialogActions className='px-4 pb-4'>
          <button className='btn btn-outline-secondary' onClick={() => { hideContinueDialog(); handleClose(); }}>
            No (close)
          </button>
          <button className='btn btn-outline-primary' onClick={continueAnotherScan}>
            Yes (continue)
          </button>
        </DialogActions>
      </Modal> 

      <Modal show={showDialog} size={'md'} onHide={handleClose} centered>
        <DialogTitle disableTypography={true}>
          <h5>Batch Scan</h5>
        </DialogTitle>
        <DialogContent>
          {result.msg || "Scanning..."}
        </DialogContent>
        <DialogActions className='px-4 pb-4'>
          <button className='btn btn-outline-danger' onClick={handleCancel} >
            Cancel
          </button>
        </DialogActions>

        {isScanning? <LinearProgress /> : ""}
        {isUploading ? <LinearProgress variant="determinate" value={uploadingPercent} /> : ""}
      </Modal>
    </div>
  )
}