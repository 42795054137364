import { createSlice } from '@reduxjs/toolkit';


export const sleepLocationSlice=createSlice({
    name:"isSleep",
    initialState:0,
    reducers:{
        isSleep:(state, action)=>{
            state=action.payload
            return state
        }
    }
})

export const { isSleep} = sleepLocationSlice.actions

export default sleepLocationSlice.reducer