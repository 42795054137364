import axios from 'axios';
import authHeader from './authHeader';
import redux from '../redux/store';
import {setCommitHash} from '../redux/apiCommitHash';
import {setConnectionInternet, setConnectionServer} from '../redux/connections';
import storages from '../constants/storages';
import tokenService from './tokenService';
const API_URL = process.env.REACT_APP_API_URL;
let disconnectTimeout = null;

class AdminService {
  onLoad() {
    return callApi('desktop/on-load', {}, { headers: authHeader() });
  }
  userLeave(data) {
    return callApi('desktop/userLeave', data, { headers: authHeader() });
  }
  //Login
  login(data) {
    return callApi('auth/login', data, {});
  }
  otpLogin(data) {
    return callApi('auth/otp-login', data, {});
  }
  //Logout
  logout(data) {
    return callApi('auth/logout', data, { headers: authHeader() });
  }
  //forgotPassword
  forgotPassword(data) {
    return callApi('auth/forgotPassword', data, {});
  }
  resetPassword(data) {
    return callApi('auth/reset-password', data, {});
  }
  //updatePassword
  updatePassword(data) {
    return callApi('auth/updatePassword', data, {headers: authHeader()});
  }
  //requireCode
  requireCode(data) {
    return callApi('auth/requireCode', data, {});
  }
  //verifyCode
  verifyCode(data) {
    return callApi('auth/verifyCode', data, {});
  }
  //get type info from username
  getInfoFromUsername(data) {
    return callApi('desktop/getInfoFromUsername', data, {headers: authHeader() });
  }

  //update user status
  updateUserStatus(data) {
    return callApi('desktop/updateUserStatus', data, {headers: authHeader() });
  }

  //update user status
  updateReceptionStatus(data) {
    return callApi('desktop/update-reception-status', data, {headers: authHeader() });
  }

  //update own camera id
  updateSelfPartId(participantID, jitsiMeetId) {
    return callApi('desktop/updateSelfPartId', {participantID, jitsiMeetId}, {headers: authHeader() });
  }

  //update Call Tag
  updateCallTag(data) {
    return callApi('desktop/updateCallTag', data, {headers: authHeader() });
  }

  //end current call.
  currentCallEnd(data) {
    return callApi('desktop/currentCallEnd', data, {headers: authHeader() });
  }

  //join User And Location
  joinUserAndLocation(data) {
    return callApi('desktop/joinUserAndLocation', data, { headers: authHeader() });
  }
  
  //get all reception user list
  getAllReceptionList() {
    return callApi('desktop/getAllReceptionList', {}, { headers: authHeader() });
  }

  //insert log with calltag on location
  insertLocationLog(data) {
    return callApi('desktop/locationLog', data, {headers: authHeader() });
  }

  //update log for emoji on location
  updateLocationLog(data) {
    return axios.put(API_URL + 'desktop/locationLog', data, {headers: authHeader() });
  }

  getAllLocations() {
    return callApi('auth/allLocations');
  }

  updateLastActivity(username) {
    return callApi(`desktop/updateLastActivity`,{username:username},{headers: authHeader()});
  } 
  createPaymentRequest(data) {
    return callApi(`desktop/payment`, data, {headers: authHeader()});
  }
  createPaymentApi(data) {
    return callApi(`desktop/payment/api/sale`, data, {headers: authHeader()});
  }
  updatePayment(trID, data) {
    return callApi(`desktop/payment/update/${trID}`, data, {headers: authHeader()});
  }
  printReceipt(trID) {
    return callApi(`desktop/payment/print-receipt/${trID}`, {}, {headers: authHeader()});
  }
  checkStatus(data) {
    return callApi(`desktop/checkStatus`,data,{headers: authHeader()});
  }
  sendMessage(data ,onUploadProgress) {
    var options={ headers: authHeader() }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/sendMessage`, data, options);
  }
  uploadScanResult(data, onUploadProgress) {
    var formData = new FormData();
    if(data.file) {
      formData.append("file", new Blob([data.file], {type: "image/jpg"}))
    }
    for(let i in data) {
      if(i !== "file") {
        formData.append(i, data[i])
      }
    }
    var options = { headers: {...authHeader(), "Content-Type": "multipart/form-data"} }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/upload-scan-result`, formData, options);
  }
  getBatchesList(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/batches`, data, options);
  }
  galleryList(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/gallery`, data, options);
  }
  galleryRemove(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/gallery/remove`, data, options);
  }
  checkSharedFiles(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/shared-files/check`, data, options);
  }

  getMessages(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/messages`, data, options);
  }
  readMessage(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/messages/read`, data, options);
  }
  locationCallAdd(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/location-call/add`, data, options);
  }
  checkHealth(){
    return callApi(`check-health`);
  }
  getDisplayNameReception(data){
    var options={ headers: authHeader() }
    return callApi('desktop/reception/displayname', data, options)
  }
  getLangsDicts(){
    var options={ headers: authHeader() }
    return callApi(`desktop/get-langs-dicts`, {}, options);
  }
  sendScanRequest(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/scan-request`, data, options);
  }
  updateCardDetection(data) {
    var options={ headers: authHeader() }
    return callApi("desktop/update-card-detection", data, options)
  }
  changeLocationLang(data) {
    var options={ headers: authHeader() }
    return callApi("desktop/change-location-lang", data, options)
  }
  sendIssueReport(data, onUploadProgress) {
    var formData = new FormData();
    if(data.recSc) {
      formData.append("recSc", new Blob([data.recSc], {type: "image/png"}))
    }
    if(data.recLog) {
      formData.append("recLog", new Blob([data.recLog], {type: "text/plain"}))
    }
    for(let i in data) {
      if(i !== "recSc" && i !== "recLog") {
        formData.append(i, data[i])
      }
    }
    var options = { headers: {...authHeader(), "Content-Type": "multipart/form-data"} }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/issue-report`, formData, options);
  }
  updateIssueReport(data, onUploadProgress) {
    var formData = new FormData();
    if(data.locSc) {
      formData.append("locSc", new Blob([data.locSc], {type: "image/png"}))
    }
    if(data.locLog) {
      formData.append("locLog", new Blob([data.locLog], {type: "text/plain"}))
    }
    for(let i in data) {
      if(i !== "locSc" && i !== "locLog") {
        formData.append(i, data[i])
      }
    }
    var options = { headers: {...authHeader(), "Content-Type": "multipart/form-data"} }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/update-issue-report`, formData, options);
  }
  hangUpHoldCall(data) {
    var options={ headers: authHeader() }
    return callApi("desktop/hang-up-hold-call", data, options)
  }
  createActivityLog(data) {
    var options={ headers: authHeader() }
    return callApi("desktop/activity-log/create", data, options)
  }
  getListLocations(data) {
    var options={ headers: authHeader() }
    return callApi("desktop/locations/list", data, options)
  }
  auth0Login(data) {
    return callApi("auth/auth0-login", data, {})
  }
  requestCall(data){
    var options={ headers: authHeader() }
    return callApi("desktop/call/request", data, options)
  }
  chat(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/messages/send`, data, options);
  }
  uploadChatAttachment(data, onUploadProgress, cancelToken) {
    var formData = new FormData();
    if(data.file) {
      formData.append("file", new Blob([data.file], {type: data.file.type}), data.file.name)
    }
    for(let i in data) {
      if(i !== "file") {
        formData.append(i, data[i])
      }
    }

    var options={ headers: authHeader() }
    if(onUploadProgress) options.onUploadProgress = onUploadProgress;
    if(cancelToken) options.cancelToken = cancelToken;
    return callApi(`desktop/messages/attachment`, formData, options);
  }
  locationIsAway(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/location-is-away`, data, options);
  }
  updateCallHoldStatus(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/update-call-hold-status`, data, options);
  }
  getListScreens(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/screen/list`, data, options);
  }
  getBoards(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/boards`, data, options);
  }
  getBoardMessages(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/boards/messages`, data, options);
  }

  sendMessageToBoard(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/boards/messages/send`, data, options);
  }
  getLocationBoards(data) {
    var options = { headers: authHeader() };
    return callApi(`desktop/boards`, data, options);
  }
  connectBoardToLocation(data) {
    var options = { headers: authHeader() };
    return callApi(`desktop/boards/connect/pin`, data, options);
  }
  boardLogoutByUser(data) {
    var options = { headers: authHeader() };
    return callApi(`desktop/boards/logout-by-user`, data, options);
  }
}

async function callApi (url, data, options){
  return new Promise (async (resolve,reject)=>{
    try{
      if(
        console.logLevel && (console.logLevel == "debug" || console.logLevel == "silly")
      ) {
        let sanitizedData, sanitizedOptions
        if(data) {
          sanitizedData = JSON.parse(JSON.stringify(data));
          delete sanitizedData.password;
          delete sanitizedData.msg?.file;
          delete sanitizedData.msg?.files;
        }
        if(options) {
          sanitizedOptions = JSON.parse(JSON.stringify(options));
          delete sanitizedOptions.headers?.Authorization;
        }
        console.debug(`callApi ${API_URL + url} ${JSON.stringify(sanitizedData)} ${JSON.stringify(sanitizedOptions)}`)
      }
      if(!data) {
        data = {}
      }
      const appVersion = localStorage.getItem("lucas_therapies_version")
      if(appVersion) {
        data.appVersion = appVersion;
      }
      var res = await axios.post(API_URL + url, data,options);
      if(res.status >= 200 && res.status < 300) {
        if(res.data?.code == 503) {
          if(!disconnectTimeout) {
            console.log("Disconnected with server reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("server")
            }, 11 * 1000);
          }
        }else{
          clearTimeout(disconnectTimeout);
          disconnectTimeout=null
          redux.dispatch(setConnectionServer(true))
          redux.dispatch(setConnectionInternet(true))
        }
      } 
      if(options && options.headers && options.headers.Authorization){
        if(res && res.data && res.data.code === 401 && url!='auth/logout'){
          tokenService.remove();
          if(window.electron) {
            window.electron.reload();
          } else {
            // This method is set in Doctor using useBeforeUnload. It may be present or may not
            if(typeof window.location.replaceWithoutWarn === "function") {
              window.location.replaceWithoutWarn("#/")
            } else {
              window.location.href="#/"
            }
          }
          console.debug("Authorization failed. User will be logged out")
          return reject(new Error("Authorization failed. User will be logged out"))
        }
      }
      if(res && res.data && res.data._API && redux.getState().apiCommitHash!= res.data._API){
        redux.dispatch(setCommitHash(res.data._API))
      }
      return resolve(res)
    }catch(err){
      if(
        typeof err.toJSON === "function" 
        && (err.toJSON().message === 'Network Error' || err.toJSON().message.includes("timeout of 0ms exceeded"))
      ) {
        if(window.navigator.onLine) {
          if(!disconnectTimeout) {
            console.log("Disconnected with server reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("server")
            }, 11 * 1000);
          }
        } else {
          if(!disconnectTimeout) {
            console.log("Disconnected with internet reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("internet")
            }, 11 * 1000);
          }
        }
      } else if (err.response?.status === 502 || err.response?.status === 503) {
        if(!disconnectTimeout) {
          console.log("Disconnected with server reason")
          disconnectTimeout = setTimeout(() => {
            disconnected("server")
          }, 11 * 1000);
        }
      } else if (axios.isCancel(err)) { // request canceled by user, no need to log
        return reject(err)
      }

      console.error(err)
      return reject(err)
    }
  })
}

function disconnected(reason) {
  console.alert("Disconnected fot 11 seconds with " + reason + " reason")
  if(reason === "server") {
    redux.dispatch(setConnectionServer(false))
  } else {
    redux.dispatch(setConnectionInternet(false))
  }
}

export default new AdminService();

