import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, FormGroup, FormControlLabel, Input, InputLabel } from '@mui/material/';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AdminService from '../../services/api';
import Str from '../../constants/string';
import Storages from '../../constants/storages';
import { HiddenMenu } from '../../Components/Location/HiddenMenu'
import { authenticate } from '../../context/socket';
import VersionComponent from '../../Version'
import { useSelector } from 'react-redux';
import Connection from '../../Components/User/Connection';
import ReactLoading from 'react-loading';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import tokenService from '../../services/tokenService';
import {createAuth0Client} from '@auth0/auth0-spa-js';
import { colors } from '@mui/material';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const ButtonLogin = styled(Button)(({
  theme: {palette, mode}
}) => ({
    color: "#ffffff",
    backgroundColor: colors.green[400],
    '&:hover': {
      backgroundColor: colors.green[600],
    },
    padding: "10px 19px",
    borderRadius: "40px",
    outline: "0px",
    fontWeight: "bolder",
    '& span': {
      paddingTop: "1px",
      alignItems: "start",
    },
    width: "100%"
}))

const Login = (props) => {
  const { history } = props;
  const [errorsUsername, setErrorsUsername] = useState('');
  const [errorsPassword, setErrorsPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [clickedLogin, setClickedLogin] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const connections = useSelector((state) => state.connections)
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const usernameRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    //get information from localStorage.
    let remember;

    remember = localStorage.getItem(Storages.LOCAL_IS_REMEMBER) ? true : false;
    setIsRemember(remember);

    checkConnection();

    if(window.location.hash === "#/reset-password"){
      setIsResetPassword(true)
    }
  }, []);

  useEffect(() => {
    if(connections.internet && connections.server) {
      checkConnection();
    }
  }, [connections])

  const auth0Login = async () => {
    try{
      setShowLoading(true)
      if(window.electron) {
        const loginRes = await window.electron.auth0Login();
        if(loginRes.code === 0) {
          const token = loginRes.data
          clickLogin(token, null, isRemember, true);
        } else {
          toast.error("Login error. Please try again.", {autoClose: 5000});
        }
      } else {
        const auth0Client = await createAuth0Client({
          domain: process.env.REACT_APP_AUTH0_DOMAIN,
          clientId: process.env.REACT_APP_AUTH0_CLIENTID,
        });
        await auth0Client.loginWithPopup({
          authorizationParams: { redirect_uri: window.location.origin },
        });
        const token = await auth0Client.getTokenSilently();
        if(token) {
          clickLogin(token, null, isRemember, true);
        } else {
          toast.error("Login error. Please try again.", {autoClose: 5000});
        }
      }
    } catch (err) {
      console.error(9888, err)
      toast.error("Login error. Please try again.", {autoClose: 5000});
    } finally {
      setShowLoading(false)
    }
  }

  const checkConnection = async () => {
    setIsLoading(true)
    try {
      var res = await AdminService.checkHealth()
      if(res.data.code === 0){        
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function checkIfEnter(event) {
    if (event.key === 'Enter') {
      handleClickButton();
    }
  }

  const changeName = (event) => {
    // event.preventDefault();
    // setUsername(event.target.value);
    if (event.target.value.length > 0) {
      setErrorsUsername('');
    }
  }

  const changePassword = (event) => {
    // event.preventDefault();
    // setPassword(event.target.value);
    if (event.target.value.length > 0) {
      setErrorsPassword('');
    }
  }

  const handleRememberMe = (event) => {
    setIsRemember(event.target.checked);
    if(event.target.checked) {
      localStorage.setItem(Storages.LOCAL_IS_REMEMBER, true);
    } else {
      localStorage.removeItem(Storages.LOCAL_IS_REMEMBER);
    }
  }

  const handleClickButton = () => {
    let cnt = 0;
    if (clickedLogin) { cnt++ }
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if(username?.length === 0) { setErrorsUsername(Str.STR_INPUT_USERNAME); cnt++; }
    if(password?.length === 0) { setErrorsPassword(Str.STR_INPUT_PASSWORD); cnt++; }
    // if (username.length === 0) { setErrorsUsername(Str.STR_INPUT_USERNAME); cnt++; }
    // if (password.length === 0) { setErrorsPassword(Str.STR_INPUT_PASSWORD); cnt++; }
    if (cnt === 0) {
      setShowLoading(true)
      clickLogin(username, password, isRemember, false);
    }
  }

  const clickLogin = async (userId, userPwd, remember, auth0Login) => {
    try{
      // if not remember me, delete all remember me data
      if (!remember) {
        tokenService.remove();
      }

      let data = {};
      if(auth0Login) {
        data = {
          auth0Token: userId
        }
      } else {
        data = {
          username: userId,
          password: userPwd
        };
      }
      
      setClickedLogin(true);

      const response = await AdminService.login(data)
      if (response.data.code !== 200) {
        if (response.data.code === 601){
          window.confirmAsync.show(
            <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Confirm</h6>, 
            <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
              {response.data.message} If you login, another user will be logged out.
            </span>, 
            [
              { value: 1, color: "primary", text: "Yes", close: 1 },
              { value: 0, color: "default", text: "No", close: 1 },
            ]
          ).then(async (value) => {
            if(value === 1) {
              const res = await AdminService.login({...data, force:true})
              if (res.data.code !== 200) {
                setShowLoading(false)
                toast.error(res.data.message);
              } else {
                successLogin(res)
              }
            } else {
              setShowLoading(false)
            }
          })
        } else {
          setShowLoading(false)
          let autoClose = 5000;
          if(response.data.code === -6) {
            // code -6 has long message. so more autoClose time
            autoClose = 10 * 1000;
          }
          toast.error(response.data.message, {autoClose});
        }
      } else {
        successLogin(response)
      }
      setClickedLogin(false);
    } catch (err) {
      console.error(err)
      setClickedLogin(false);
      setShowLoading(false)
      toast.error(Str.STR_SERVER_ACCESS_ERROR);
    }
  }

  const successLogin=(response)=>{
    var token=response.data.data.token
    if(!token) {
      setShowLoading(false)
      console.error(1237, "No token in login response")
      return toast.error("There is an error in server response. Please try again later.")
    }
    tokenService.set(token)
    authenticate();
    let data = response.data.data.data;
    
    if(window.electron && data.versionResult && data.versionResult.code !== 0) {
      if(data.versionResult.data?.forceUpdate) {
        window.alert("We have made some changes to Virtual Sally. You have to update to continue using the application.");
        window.electron.downloadLastVersion(data.versionResult.data)
        return
      } else {
        if(window.confirm("We have made some changes to Virtual Sally. Do you want to download and install new version?")) {
          window.electron.downloadLastVersion(data.versionResult.data)
          return
        }
      }
    }

    // history.replace('/on-load');
    history.replace('/receptionist');
  }

  const showForgotPassword = () => {
    setIsForgotPassword(true)
  }
  
  const hideForgotPassword = () => {
    setIsForgotPassword(false)
  }

  const hideResetPassword = () => {
    setIsResetPassword(false)
  }

  return (
    <div className="root">
      {showLoading ? (
        <div>
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "#000",
              opacity: 0.75,
              zIndex: 1000,
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              width: "50px",
              height: "50px",
              zIndex: 1001,
              top: "50%",
              left: "50%",
              margin: "-25px 0 0 -25px",
            }}
          >
            <ReactLoading type={"spin"} color={"#0085d2"} />
          </div>
        </div>
      ) : (
        ""
      )}
      {!connections.internet ? (
        <Connection msg="It seems like there is no internet connection. Retrying…" />
      ) : !connections.server ? (
        <Connection
          msg="We are currently upgrading and updating our service. 
            We apologize for any inconvenience this may cause. 
            Please be patient while we apply these necessary changes, 
            your system will be back up shortly."
        />
      ) : isLoading ? (
        <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
      ) : (
        <>
          <HiddenMenu />
          <img className="top_tap" src={window.locationTopBanner} alt="top_tap" />
          <img className="bottom_tap" src={window.locationBottomBanner} alt="bottom_tap" />
          <div
            className={"w-100 d-flex align-items-center"}
            style={{ height: "100vh" }}
          >
            <div className="col-sm-1 col-md-3 col-xl-4 d-none d-sm-block"></div>
            <div className="col-sm-10 col-md-6 col-xl-4 col-12">
              {isForgotPassword && <ForgotPassword hideForgotPassword={hideForgotPassword}/>}
              {isResetPassword && <ResetPassword hideResetPassword={hideResetPassword}/>}
              {!isForgotPassword && !isResetPassword &&
                <>
                  <div className="w-100 d-flex justify-content-center">
                    <h3>Welcome to Virtual Sally</h3>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <h5>Please login</h5>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="username">Username</InputLabel>
                      <Input
                        id='username'
                        error={!!errorsUsername}
                        helperText={errorsUsername || ""}
                        inputRef={usernameRef}
                        onChange={changeName}
                        onKeyDown={checkIfEnter}
                        label="Username"
                      />
                    </FormControl>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <FormControl fullWidth className="mt-2" variant='standard'>
                    <InputLabel htmlFor="password">Password</InputLabel>
                      <Input
                        id="password"
                        error={!!errorsPassword}
                        helperText={errorsPassword || ""}
                        inputRef={passwordRef}
                        onChange={changePassword}
                        onKeyDown={checkIfEnter}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <button
                              className="btn btn-sm btn-light rounded-circle"
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <Visibility fontSize="small" />
                              ) : (
                                <VisibilityOff fontSize="small" />
                              )}
                            </button>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="w-100 d-flex justify-content-start mt-2">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRemember}
                            onChange={handleRememberMe}
                          />
                        }
                        label="Remember me"
                      />
                    </FormGroup>
                  </div>
                  <div className="w-100 d-flex justify-content-center mb-2">
                    <ButtonLogin
                      startIcon={<ExitToAppIcon />}
                      onClick={handleClickButton}
                    >
                      Direct Login
                    </ButtonLogin>
                  </div>
                  <div className="w-100 d-flex justify-content-center mb-2">
                    <ButtonLogin
                      startIcon={<ExitToAppIcon />}
                      onClick={() => auth0Login()}
                    >
                      Auth0 Login
                    </ButtonLogin>
                  </div>
                  <div className="w-100 d-flex justify-content-start">
                    <a onClick={showForgotPassword} href="javascript:void(0)">
                      Forgot password?
                    </a>
                  </div>
                </>
              }
            </div>
            <div className="col-sm-1 col-md-3 col-xl-4 d-none d-sm-block"></div>
          </div>
          <VersionComponent />
        </>
      )}
    </div>
  );
};
export default withRouter(Login);
