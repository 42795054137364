import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import HairImage from '../../assets/images/hair_icon.png';
import ColorButton from '../../Components/Patient/ColorButton';
import IconColorButton from '../../Components/Patient/IconColorButton';
import Str from '../../constants/string';
import Color from '../../constants/usercolor';
import {HiddenMenu} from  '../../Components/Location/HiddenMenu'
import apiService from '../../services/api';
import { useSelector ,useDispatch} from 'react-redux';
import BackButton from '../../Components/Patient/BackButton';
import { toast } from 'react-toastify';
import storages from '../../constants/storages';
import { setScreens } from '../../redux/screen';

const Screens = (props) => {
    const { isCalling, handleShowCallDialog } = props;
    const [logo, setLogo] = useState('');
    const [showLangPrompt, setShowLangPrompt] = useState(window.i18n.multiLang)
    // on this page we need a state for this to be able to change it
    const [callReceptionButtonText, setCallReceptionButtonText] = useState("") 
    const screens = useSelector((state) => state.screens)
    const [screen,setScreen]=useState({})
    const [title,setTitle]=useState('')
    const [subtitle,setSubtitle]=useState('')
    const [heading,setHeading]=useState('')
    const [stack,setStack]=useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        let logoName = localStorage.getItem("logo");
        if (!logoName || logoName === "null") {
          setLogo("")
        } else if (logoName.startsWith("http://") || logoName.startsWith("https://")) {
          setLogo(logoName)
        } else {
          setLogo(process.env.REACT_APP_UPLOAD_URL + logoName)
        }

        return () => {
          clearTimeout(window.goBackToWelcomeTimeout)
        }
    }, []);

    useEffect(()=>{
      var page={}
      if(stack.length>0){
        var slug=stack[stack.length-1]
        page=screens.find(sc=>sc.slug===slug)
        if(page){
          setScreen(page)
          if(page.isMain){
            setStack([page.slug])
          }
          return
        }
      }
      else {
        page=screens.find(sc=>sc.isMain===1)
        if(page && page.slug)  {
          let arr=[...stack]
          arr.push(page.slug)
          setStack(arr)
          setScreen(page)
        }
        else {
          // no found main screen
          dispatch(setScreens([]))
        }
      }
    },[screens])

    
    useEffect(() => {
      if(!showLangPrompt && window.i18n.multiLang) { //we are showing welcome && multiLang
        window.goBackToWelcomeTimeout = setTimeout(() => {
          window.i18n.currentLangCode = window.i18n.defaultLang;
          setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
          setShowLangPrompt(true)
          sendLangToReceptions();
        }, 30 * 1000)
      }
    }, [showLangPrompt])

    useEffect(() => {
      if(isCalling) return () => {}
      window.i18n.currentLangCode = window.i18n.defaultLang; // when come back to this page, reset lang to default
      setShowLangPrompt(window.i18n.multiLang) // when come back to this page, go to lang prompt
      setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      sendLangToReceptions();
      // navigate to main screen
      let sc=screens.find((s)=>s.isMain== 1)
      if(!sc) return () => {toast.error("Page not found!")}
      setStack([sc.slug])
      setScreen(sc)
    }, [isCalling])

    useEffect(()=>{
      let loc=localStorage.getItem(storages.LOCAL_LOCATION_NAME)
      let company=localStorage.getItem(storages.LOCAL_COMPANY_NAME)
      if(screen && screen.title){
        let titleTemp=screen.title 
        titleTemp=titleTemp.replaceAll("[location]",loc)
        titleTemp = titleTemp.replaceAll("[company]",company)
        setTitle(titleTemp)
      }
      if(screen && screen.subtitle){
        let subtitleTemp=screen.subtitle 
        subtitleTemp=subtitleTemp.replaceAll("[location]",loc)
        subtitleTemp = subtitleTemp.replaceAll("[company]",company)
        setSubtitle(subtitleTemp)
      }
      if(screen && screen.heading){
        let headingTemp=screen.heading 
        headingTemp=headingTemp.replaceAll("[location]",loc)
        headingTemp = headingTemp.replaceAll("[company]",company)
        setHeading(headingTemp)
      }
      if(screen.isMain ==1 ){
        window.i18n.currentLangCode = window.i18n.defaultLang; // when come back to this page, reset lang to default
        setShowLangPrompt(window.i18n.multiLang) // when come back to this page, go to lang prompt
        setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
        sendLangToReceptions();
      }
    },[screen])

    const changeLang = (lang) => {
      window.i18n.currentLangCode = lang.code
      setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      setShowLangPrompt(false)
      sendLangToReceptions();
    }

    const sendLangToReceptions = async () => {
      try{
        const sendLangResult = await apiService.changeLocationLang({
          newLang: window.i18n.currentLangCode
        })
        if(sendLangResult.data?.code !== 0) {
          console.error(8791, sendLangResult)
        }
      } catch (err) {
        console.error(9901, err)
      }
    }

    const handleCall = () => {
      if (isCalling) {
          return;
      }
      props.handleShowCallDialog(Str.STR_NORMAL, false);
    }

    const handleOnClickBtn=(btn)=>{
      if(btn.type==='redirect'){
        let slug=btn.value
        let sc=screens.find((s)=>s.slug===slug)
        if(!sc) return toast.error("Page not found!")
        if(sc.isMain){
          setStack([sc.slug])
        }
        else {
          let arr=[...stack]
          arr.push(sc.slug)
          setStack(arr)
        }
        setScreen(sc)
        return
      }
      if(btn.type==='call'){
        if(isCalling) return;
        let callTag=btn.value
        let callTagColor=btn.callColor
        handleShowCallDialog(callTag,false,callTagColor)
      }

      if(btn.type==='kiosk'){
        if (isCalling) return;
        // open kiosk
        if(localStorage.getItem("integrated_kiosk") == "1") {
          handleShowCallDialog(Str.KIOSK, true);
        } else {
        let slug=btn.value
        let sc=screens.find((s)=>s.slug===slug)
        if(!sc) return  toast.error("Page not found!")
        if(sc.isMain){
          setStack([sc.slug])
        }
        else {
          let arr=[...stack]
          arr.push(sc.slug)
          setStack(arr)
        }
        setScreen(sc)
        return
        }
      }
      if(btn.type==='webPage'){
        if (isCalling) return;
        // open web view
        let url=btn.value
        if(!url) return toast.error("Page not found!")
        window.electron && window.electron.showWebView(url)
      }
    }

    const handleBackButton=()=>{
      if(stack.length > 1){
        var arr=[...stack]
        arr.pop()
        let slug=stack[arr.length-1]
        if(slug){
          let sc=screens.find((s)=>s.slug===slug)
          if(!sc) {
            toast.error("Page not found. Redirect to Main Screeen.")
            sc=screens.find(s=>s.isMain===1)
            arr=[sc.slug]
          }
          setScreen(sc)
          setStack(arr)
        }
      }
    }

    return (
        <div className='root'>
            <HiddenMenu />
            <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='d-flex flex-column align-items-center justify-content-start w-100 h-100'> 
              <div className='d-flex align-items-end justify-content-center p-3' 
                style={{height: "25vh", width: "45vw"}}>
                { logo &&<img style={{maxHeight: "100%", maxWidth: "100%", zIndex: 1}} src={logo}/>}
              </div>
              {/* {showLangPrompt ? 
                <>
                  <label className='welcom_lab mt-5'>
                    {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
                  </label>
                  <label className='main_lab mb-4'>
                    {window.i18n.getString("chooseLanguage")}
                  </label>
                  <div className='bottom_div mb-5'>
                    {window.i18n.langs.map(lang => (
                      <ColorButton onClicked={() => changeLang(lang)} Title={lang.title} Color={Color.PURPLE_BTN_COLOR}/>
                    ))}
                  </div>
                </> 
              :*/}
                <>
                <label className='welcom_lab'>
                    {title}
                  </label>
                  <label className='sub_lab' id='welcom_sub_lab'>
                    {subtitle}
                  </label>
                  <label className='main_lab'>
                    {heading}
                  </label>
                  <div className='text-center row w-75 ' style={{zIndex:1,justifyContent:"center"}} >
                      {screen && screen.buttons && screen.buttons[0] && screen.buttons.map((btn)=>{
                          return(
                            <ColorButton onClicked={()=>handleOnClickBtn(btn)}   Color={isCalling ? Color.GRAY_BTN_COLOR :btn.color} shape={btn.shape}
                              Title={btn.text} />
                          )
                      })}
                  </div>
                </>
               {/* } */}
            </div>
            <div id="call_div">
                <IconColorButton onClicked={handleCall} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR} 
                  Icon={HairImage} Title={callReceptionButtonText} />
            </div>
            {
              stack.length > 1 &&
              <div id="back_div">
                  <BackButton onClicked={handleBackButton} />
              </div>
            }

        </div>
    )
}

Screens.prototype = {

}

export default withRouter(Screens);