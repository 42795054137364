import { createSlice } from '@reduxjs/toolkit';

export const speakers = createSlice({
	name: 'speakers',
	initialState: [],
	reducers: {
		addSpeaker: (state, action) => {
			
			var index=state.findIndex((m)=>m.deviceId===action.payload.deviceId)
			if(index >-1){
				state[index].label=action.payload.label
				return state
			}
			const sp = {
				id: state.length,
				deviceId: action.payload.deviceId,
				label: action.payload.label,
				selected: action.payload.selected,
			};
			state.push(sp);
			return state
		},
		selectSpeaker: (state, action) => {
			var {index,deviceId}=action.payload

			state.forEach((el, index) => {
				el.selected = el.deviceId===deviceId;
			});
			if(index  || index===0){
				const selectedIndex = state.findIndex((sp) => sp.id === action.payload.index);
				state[selectedIndex].selected = true;
			}
			return state
		},
		deleteSpeaker: (state, action) => {
			const updated = state.filter((sp) => sp.deviceId != action.payload.deviceId);

			const ret = updated.map((sp, index) => {
				let item = {
					id: index,
					deviceId: sp.deviceId,
					label: sp.label,
					selected: sp.selected,
				}
				return item;
			});
			return ret;
		},
		deleteSpeakers: () => {
			return [];
		},
		updateSpeaker:(state, action)=>{
			var {label,deviceId}=action.payload
			state=state.map((sp)=>{
				if(sp.deviceId ===deviceId){
					sp.label=label
				}
				return
			})
		}
	},
});

export const { addSpeaker, selectSpeaker, deleteSpeaker, deleteSpeakers ,updateSpeaker } = speakers.actions;

export default speakers.reducer;
