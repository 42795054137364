import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal-style.css';
import Backspace from "@mui/icons-material/Backspace";
import Check from "@mui/icons-material/Check";
export const NumPadModal = ({ showModal, handleClose, handleCheck, updateTimeOut}) => {
    const [code,setCode]=React.useState('')
    const onChange=(n)=>{
        if(code && code.length>=6) return        
        setCode(code?code.concat(n):n.toString())
        updateTimeOut()
    }
    const onKeyUp = (e) => {
      if(e.key === "Backspace"){
        clean()
      } else if (!isNaN(e.key)) {
        onChange(e.key)
      }
    }
    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        e.stopPropagation()
        handleCheck(code)
      } 
    }
    const clean=()=>{
        setCode(code.slice(0, -1))
        updateTimeOut()
    }
    useEffect(()=>{
        setCode('')
    },[showModal])
    return (
        <Modal className='' show={showModal} size={'sm'} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="pad">
                    <div className="numpad-row">
                        <input className="input-numpad" placeholder='Enter Code' value={code} onKeyUp={onKeyUp} onKeyDown={onKeyDown} />
                    </div>    
                    <div className="numpad-row">
                        <button className="key" onClick={() => onChange(1)}>
                        1
                        </button>
                        <button className="key" onClick={() => onChange(2)}>
                        2
                        </button>
                        <button className="key" onClick={() => onChange(3)}>
                        3
                        </button>
                    </div>
                    <div className="numpad-row">
                        <button className="key" onClick={() => onChange(4)}>
                        4
                        </button>
                        <button className="key" onClick={() => onChange(5)}>
                        5
                        </button>
                        <button className="key" onClick={() => onChange(6)}>
                        6
                        </button>
                    </div>
                    <div className="numpad-row">
                        <button className="key" onClick={() => onChange(7)}>
                        7
                        </button>
                        <button className="key" onClick={() => onChange(8)}>
                        8
                        </button>
                        <button className="key" onClick={() => onChange(9)}>
                        9
                        </button>
                    </div>
                    <div className="numpad-row">
                        <button className="key-action" onClick={()=>handleCheck(code)}>
                            <Check/>
                        </button>
                        <button className="key" onClick={() => onChange(0)}>
                        0
                        </button>
                        <button className="key-action" onClick={()=>clean()} >
                        <Backspace />
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
} 