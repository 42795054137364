import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { SocketContext } from '../../context/socket';
import apiService from '../../services/api';
import { toast } from 'react-toastify';

const CardDetection = () => {
  const cardDetectionEnable = useRef(false);
  const cardDetectionCount = useRef(0);
  const [cardDetectionMsg, setCardDetectionMsg] = useState("");
  const batchIDRef = useRef()
  const socket = useContext(SocketContext);
  const [showModal, setShowModal] = useState(false)
  const uploadingQueueRef = useRef([]);
  const uploadIntervalRef = useRef(null);
  const fromReceptionRef = useRef("");

  useEffect(() => {
    socket.on('msg', ({event, msg, from})=>{
      if(event === 'card-detection-on'){
        if(window.electron) {
          cardDetectionOn(msg, from)
        }
      }
      if(event === 'card-detection-off'){
        setShowModal(false)
        reset()
      }
    })

  }, [])

  const cardDetectionOn = async (msg, from) => {
    try{
      const tfjsIsEnable = localStorage.getItem("tfjs_is_enable")
      if(!tfjsIsEnable) {
        await apiService.sendMessage({
          to: from,
          event: "card-detection-res",
          msg: {code: 0, msg: "This location does not have ID card detection."}
        })
        return;
      }

      reset()
      setShowModal(true)
      cardDetectionEnable.current = true;
      fromReceptionRef.current = from
      detectOne([])
      batchIDRef.current = localStorage.getItem("lucas_therapies_logined_username") + "-" + new Date().getTime()
    } catch (err) {
      console.error(err)
      toast.error(window.i18n.getString("serverError"), {autoClose: 10000})
    }
  }

  const reset = () => {
    cardDetectionCount.current = 0;
    cardDetectionEnable.current = false;
    clearInterval(uploadIntervalRef.current)
    setCardDetectionMsg(window.i18n.getString("cardDetectionMsg"))
    uploadingQueueRef.current = []
    fromReceptionRef.current = ""
  }

  const detectOne = async (children) => {
    const video = document.getElementById(`mainVideo`);
    let snapshot = document.createElement('canvas');
    snapshot.width = video.clientWidth*4;
    snapshot.height = video.clientHeight*4;
    let snapshotCTX = snapshot.getContext('2d')
    snapshotCTX.drawImage(video, 0, 0, video.clientWidth*4, video.clientHeight*4);
    let snapshotDataURL = snapshot.toDataURL('image/jpeg');

    window.electron.tfjsExec(snapshotDataURL.split(",").pop());
    window.electron.tfjsExecRes((event, data) => {
      if(data.code !== 0) {
        setCardDetectionMsg(window.i18n.getString("cardDetectionError"))
        apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: -1, msg: "Error while detecting ID card. " + data.msg}
        }).catch((err) => {
          console.error(err);
        })
        return;
      }

      const {boxesArray, scoresArray} = data.data;
      for(let i=0; i< boxesArray.length; i++) {
        const score = scoresArray[i];
        if(score > 0.9) {
          const bbox = boxesArray[i];
          let width = (bbox[3] - bbox[1]) * 100
          let widthPx = snapshot.width * (bbox[3] - bbox[1])
          let height = (bbox[2] - bbox[0]) * 100
          let heightPx = snapshot.height * (bbox[2] - bbox[0])
          if(width < 30 && height < 30) {
            const msg = window.i18n.getString("cardDetectionCloser");
            setCardDetectionMsg(msg)
            apiService.sendMessage({
              to: fromReceptionRef.current,
              event: "card-detection-res",
              msg: {code: 0, msg}
            }).catch((err) => {
              console.error(err);
            })
          } else {
            const msg = window.i18n.getString("cardDetectionHoldStill")
            setCardDetectionMsg(msg)
            apiService.sendMessage({
              to: fromReceptionRef.current,
              event: "card-detection-res",
              msg: {code: 0, msg}
            }).catch((err) => {
              console.error(err);
            })
            let canvas = document.createElement('canvas');
            canvas.width = widthPx*2.5;
            canvas.height = heightPx*2;
            let ctx = canvas.getContext('2d')
            ctx.drawImage(snapshot, snapshot.width * bbox[1], snapshot.height * bbox[0], widthPx, heightPx, 0, 0, widthPx*2.5, heightPx*2);

            canvas.toBlob(async (blob) => {
              const buffer = await blob.arrayBuffer()
              uploadingQueueRef.current.push({i: cardDetectionCount.current, buffer})
              uploadOne(cardDetectionCount.current);
            }, 'image/jpeg');

            cardDetectionCount.current++;
            if(cardDetectionCount.current >= 5) {
              cardDetectionEnable.current = false;
              const msg = window.i18n.getString("cardDetectionUploading")
              setCardDetectionMsg(msg)
              apiService.sendMessage({
                to: fromReceptionRef.current,
                event: "card-detection-res",
                msg: {code: 0, msg}
              }).catch((err) => {
                console.error(err);
              })
              return;
            }
            break;
          }
        }
      }

      if(cardDetectionEnable.current) {
        setTimeout(() => {
          detectOne(children)
        }, 300);
      }
    })
  }

  const uploadOne = async (i) => {
    try{
      const file = uploadingQueueRef.current.find(item => item.i === i);
      if(!file || !file.buffer) {
        return;
      }
      const axiosRes = await apiService.uploadScanResult({
        file: file.buffer, 
        batchID: batchIDRef.current,
        fromReception: fromReceptionRef.current,
        lastOneInABatch: cardDetectionCount.current >= 5 && uploadingQueueRef.current.length === 1,
      })
      if(axiosRes.data.code === 0) {
        uploadingQueueRef.current = uploadingQueueRef.current.filter(item => item.i !== i);
        if(cardDetectionCount.current >= 5 && uploadingQueueRef.current.length === 0) {
          reset()
          setShowModal(false)
          toast.success(window.i18n.getString("success"), {autoClose: 5000});
        }
      } else {
        await apiService.sendMessage({
          to: fromReceptionRef.current,
          event: "card-detection-res",
          msg: {code: axiosRes.data.code, msg: "Error while uploading: " + axiosRes.data.msg + " Retrying..."}
        })
        uploadOne(i)
      }
    } catch (err) {
      console.error(err)
      apiService.sendMessage({
        to: fromReceptionRef.current,
        event: "card-detection-res",
        msg: {code: 7, msg: "Error while uploading: " + err.message + " Retrying..."}
      }).catch((err) => {
        console.error(err);
      })
      uploadOne(i)
    }
  }

  return (
    <div style={{
      position: "absolute", 
      marginTop: "40vh",
      zIndex: 2, 
      backgroundClip: "padding-box",
      border: "1px solid rgba(0,0,0,.2)",
      borderRadius: ".3rem",
      backgroundColor: "#FFF",
      display: showModal ? "block" : "none",
      paddingInline: 0
    }} className="offset-2 col-8 offset-md-3 col-md-6 offset-xl-4 col-xl-4">
      <DialogTitle disableTypography={true}>
        <h5>{window.i18n.getString("cardDetectionTitle")}</h5>
      </DialogTitle>
      <DialogContent>
        <div style={{marginTop: "0px", marginBottom: "5px"}}>{cardDetectionMsg}</div>
      </DialogContent>
      <br/>
      <LinearProgress />
    </div>
  )
}
export default CardDetection