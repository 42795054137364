import React, { useRef, useContext, useEffect ,useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Grid,ButtonBase,CircularProgress,Tooltip} from "@mui/material";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import ScannerOutlinedIcon from '@mui/icons-material/ScannerOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import {  selectMic} from '../../redux/micSlice';
import { selectCamera } from '../../redux/cameraSlice';
import { selectSpeaker } from '../../redux/speaker';
import Storages from '../../constants/storages';
import MenuListComposition from '../Controls/MenuListComposition';
import LocationVolume from './LocationVolume';
import FilterOutlinedIcon from '@mui/icons-material/FilterOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import CameraIcon from '@mui/icons-material/Camera';
import { Settings } from "@mui/icons-material";
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import { toast } from "react-toastify";
import AdminService from '../../services/api'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import './styleReception.css'



const audioContext = new AudioContext();

export default function ToolBox ({callState, handleCall,locationEngaged,allLocations, handlePlay,
    playState,handleOpenModalPayReq,handleRequestForScan,handleModalPrintFile,
    handleLocalCamera, handleLocalMic, micro, camera,handleOpenGallery, startCardDetection,
    handleToggleUserSideBar, openUserSideBar, toggleShareScreen, 
    locationVolume, captureLocationCameraImage, handleExitFullScreenEngaged,
    handleOpenSettingModal, monitorCallStatus,handleOpenMessagingBoard,
    handleDeviceManagement, callLoading
}){

    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const speakers=useSelector((state)=>state.speakers)
    const [showPayButton, setShowPayButton] = useState(true)
    const [showCardDetection, setShowCardDetection] = useState(true)
    const [isManager,setIsManager]=useState(false)
    const [audioInputDevicesMenu, setAudioInputDevicesMenu] = useState([]); // {selected, label,id}
    const [audioOutputDevicesMenu,setAudioOutputDevicesMenu]=useState([])
    const micNodes = useRef([]);
    const notAllowedAlertShown = useRef(false);

    useEffect(() => {
        let manager=localStorage.getItem(Storages.IS_RECEPTION_MANAGER)
        manager?setIsManager(true):setIsManager(false)
        window.requestAnimationFrame(checkAudioLevelForAllMicNodes)
    }, [])

    useEffect(() => {
      getAllMicsAnalyserNodes()
    }, [mics])

    useEffect(()=>{
        if(speakers[0]){
            let arr=speakers.map((s)=>({...s,isSpeaker:true}))
            setAudioOutputDevicesMenu(arr)
        }
    },[speakers])

    const getAllMicsAnalyserNodes = async () => {
      try{
        const tmpMiceNodes = [];
        for(let mic of mics) {  
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: {
                exact: mic.deviceId,
              },
            },
          });

          const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
          const analyserNode = audioContext.createAnalyser();
          mediaStreamAudioSourceNode.connect(analyserNode);
          
          const array = new Uint8Array(analyserNode.frequencyBinCount);

          tmpMiceNodes.push({analyserNode, pcmData: array, mic})
        }
        micNodes.current = tmpMiceNodes;
      } catch(err) {
        if(!notAllowedAlertShown.current) {
          console.error(2020,err)
          err.message && toast.error("Get microphone levels: " + err.message)
          notAllowedAlertShown.current = true
        }
      }
    }

    const checkAudioLevelForAllMicNodes = async () => {
      try{
        let tmpAudioDevicesMenu = [];
        for(let micNode of micNodes.current) { 
          micNode.analyserNode.getByteTimeDomainData(micNode.pcmData);
          let level = timeDomainDataToAudioLevel(micNode.pcmData)

          let label = micNode.mic.label
          if(label.length > 30) {
            label = label.substr(0, 30) + "..."
          }
          tmpAudioDevicesMenu.push({id:micNode.mic.id,label, selected: micNode.mic.selected, bar: level*100})
        }
        setAudioInputDevicesMenu(tmpAudioDevicesMenu)

        window.requestAnimationFrame(checkAudioLevelForAllMicNodes);
      } catch (err) {
        console.error(2022,err)
      }
    }

    const timeDomainDataToAudioLevel = (samples) => {
      let maxVolume = 0;
      const length = samples.length;
      for (let i = 0; i < length; i++) {
        if (maxVolume < samples[i]) {
            maxVolume = samples[i];
        }
      }
      return parseFloat(((maxVolume - 127) / 128).toFixed(3));
    }

    useEffect(() => {
        let manager=localStorage.getItem(Storages.IS_RECEPTION_MANAGER)
        if(manager && !isManager) setIsManager(true)
        else if(!manager && isManager) setIsManager(false)
        if (!locationEngaged) {
          setShowPayButton(false)
        }
    }, [locationEngaged])

    useEffect(() => {
      if(locationEngaged) {
        const engagedObj = allLocations.find((item) => item.username === locationEngaged);
        if (engagedObj && engagedObj.deviceId) {
          setShowPayButton(true)
        } else {
          setShowPayButton(false)
        }
        if(engagedObj?.cardDetectionEnabled) {
          setShowCardDetection(true)
        } else {
          setShowCardDetection(false)
        }
      }
    }, [locationEngaged, allLocations])

    const onClickCameraSelector = (index) => {
        // let cameraDeviceId=cameras[index].deviceId
        // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
        dispatch(selectCamera({ index }));
        let label=cameras[index]?.label
        AdminService.createActivityLog({
          tags:['reception', 'camera'],
          action: "update-device",
          subAction: "camera",
          target: "__ME__",
          details: "Reception set camera to " + label
        })
    }
    const onClickMicSelector = (index) => {
        // localStorage.setItem(Storages.LOCAL_MIC_ID,deviceId)
        dispatch(selectMic({ index }));
        let label=mics[index]?.label
        AdminService.createActivityLog({
          tags: ['reception', 'microphone'],
          action: "update-device",
          subAction: "microphone",
          target: "__ME__",
          details: `Reception set microphone to ${label}`
        })
    }
    const onClickSpeakerSelector=async(index)=>{
        try {
            let speakerDeviceId = speakers[index].deviceId;
            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,speakerDeviceId)
            dispatch(selectSpeaker({ deviceId: speakerDeviceId }));
            if(speakerDeviceId){
                document.getElementById('locationAudio') && (await document.getElementById('locationAudio').setSinkId(speakerDeviceId))
                document.getElementById('mainRAudio') && (await document.getElementById('mainRAudio').setSinkId(speakerDeviceId))
                document.getElementById('alarmAudio') && (await document.getElementById('alarmAudio').setSinkId(speakerDeviceId)) 
            }
            let label=speakers[index]?.label
            AdminService.createActivityLog({
              tags: ['reception', 'speaker'],
              action: "update-device",
              subAction: "speaker",
              target: "__ME__",
              details: `Reception set speaker to ${label}`
            })
        } catch (error) {
            console.error("12364: ",error)
        }
    }

    return (
        <div className="toolbox-main"> 
            <Grid color='secondary' container spacing={1} direction='row-reverse' justifyContent="center" alignItems="center">
                {callState?
                    <>
                    <Grid item >
                        <Tooltip title='End Call'>
                            <ButtonBase className='toolbox-btn-call'  onClick={handleCall}>
                                <CallEndOutlinedIcon className='toolbox-icon'  fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <LocationVolume locationEngaged={locationEngaged} locationVolume={locationVolume} />
                    </Grid>
                    <Grid item >
                        <Tooltip title={playState?"Hold Call":"Release Call"}>
                            <ButtonBase className='toolbox-button'  onClick={handlePlay}>
                                {
                                    playState?
                                    <PhonePausedOutlinedIcon className="toolbox-icon"  fontSize="large" />
                                    :
                                    <PhoneInTalkOutlinedIcon className="toolbox-icon"  fontSize="large" />
                                }
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    </>
                    :
                    locationEngaged &&
                    <>
                    <Grid item >
                        <Tooltip title={callLoading?'Connecting...':'Call'}>
                            <ButtonBase className='toolbox-btn-call' onClick={handleCall} disabled={callLoading}>
                                {callLoading ?
                                <CircularProgress size={30}  className='toolbox-icon' />:
                                <CallOutlinedIcon className='toolbox-icon'  fontSize="large" /> }
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    </>
                }
                {
                  ((locationEngaged && (!callState || (callState && !playState))) || monitorCallStatus)
                  ? <Grid item >
                    <Tooltip title='Exit Full Screen'>
                      <ButtonBase className='toolbox-button' onClick={handleExitFullScreenEngaged}>
                        <FullscreenExitOutlinedIcon className='toolbox-icon'  fontSize="large" />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                  : ""
                }
                <Grid item>
                    <Tooltip title='Setting'>
                            <ButtonBase className='toolbox-button' onClick={handleOpenSettingModal}>
                                <Settings className='toolbox-icon'  fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                </Grid>
                {/* <Grid item>
                    <ButtonBase className='toolbox-button'>
                        <AppsOutlinedIcon className='toolbox-icon'  fontSize="large"/>
                    </ButtonBase>
                </Grid> */}
                <Grid item >
                    <Tooltip title='Gallery'>
                        <ButtonBase className='toolbox-button' onClick={handleOpenGallery}>
                            <FilterOutlinedIcon className='toolbox-icon' fontSize="large" />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                <Grid item >
                    <Tooltip title='Chat'>
                        <ButtonBase className='toolbox-button' onClick={handleToggleUserSideBar}>
                            <MenuOpenOutlinedIcon className='toolbox-icon' fontSize="large"
                            style={openUserSideBar ? {}: {transform: "rotateY(180deg)"}} />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                {showPayButton &&  callState &&
                    <Grid item>
                        <Tooltip title='Payment'>
                            <ButtonBase className='toolbox-button' onClick={handleOpenModalPayReq}>
                                <PaymentOutlinedIcon className='toolbox-icon' fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                {locationEngaged && callState &&
                    <Grid item>
                        <Tooltip title='Print'>
                            <ButtonBase className='toolbox-button' onClick={handleModalPrintFile}>
                                <PrintOutlinedIcon className='toolbox-icon' fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                {locationEngaged && 
                <Grid item>
                    <Tooltip title='Scanner'>
                        <ButtonBase className='toolbox-button' onClick={handleRequestForScan}>
                            <ScannerOutlinedIcon className='toolbox-icon' fontSize="large" />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                }
                { locationEngaged && 
                <Grid item>
                    <Tooltip title='Messaging Board'>
                        <ButtonBase className='toolbox-button' onClick={handleOpenMessagingBoard}>
                            <LibraryBooksIcon className='toolbox-icon' fontSize="large" />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                }
                {locationEngaged && showCardDetection && 
                <Grid item>
                  <Tooltip title='Card Detection'>
                    <ButtonBase className='toolbox-button' onClick={startCardDetection}>
                      <PageviewOutlinedIcon className='toolbox-icon' fontSize="large" />
                    </ButtonBase>
                  </Tooltip>
                </Grid>
                }

                {locationEngaged && 
                  <Grid item>
                    <Tooltip title="Take picture from location's camera">
                      <ButtonBase className='toolbox-button' onClick={captureLocationCameraImage}>
                        <CameraIcon className='toolbox-icon' fontSize="large" />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                }
                {locationEngaged && 
                    <Grid item>
                        <Tooltip title='Start / Stop location share screen'>
                            <ButtonBase className='toolbox-button' onClick={toggleShareScreen}>
                                <ScreenShareOutlinedIcon className='toolbox-icon' fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                {locationEngaged && isManager &&  
                  <Grid item>
                    <Tooltip title="Location Device Management ">
                      <ButtonBase className='toolbox-button' onClick={handleDeviceManagement}>
                        <GraphicEqIcon className='toolbox-icon' fontSize="large" />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                }
                <Grid item  className='toolbox-grid-devices'>
                    <Tooltip title='Start / Stop camera'>
                        <ButtonBase className='toolbox-btn-devices' onClick={handleLocalCamera}>
                            {camera?<VideocamOutlinedIcon className='toolbox-icon' fontSize="large"/>
                                :<VideocamOffOutlinedIcon className='toolbox-icon' fontSize="large"/>
                            }
                        </ButtonBase>
                    </Tooltip>
                    <MenuListComposition onClickItemSelector={(index) => onClickCameraSelector(index)} menus={cameras} title='Manage camera devices'/>
                    {/* <ButtonBase className='toolbox-option-devices'>
                        <ArrowDropUpOutlinedIcon/>
                    </ButtonBase> */}
                </Grid>
                <Grid item  className='toolbox-grid-devices'>
                    <Tooltip title='Mute /Unmute'>
                        <ButtonBase className='toolbox-btn-devices' onClick={handleLocalMic}>
                            {micro? <MicNoneOutlinedIcon className='toolbox-icon' fontSize="large"/>
                            :<MicOffOutlinedIcon className='toolbox-icon' fontSize="large" /> }
                        </ButtonBase>
                    </Tooltip>
                    <MenuListComposition onClickItemSelector={(index) => onClickMicSelector(index)}  onClickItemSpeaker={(index)=>onClickSpeakerSelector(index)}
                      menus={[
                        {
                            disabled:true,
                            label:<label> <MicNoneOutlinedIcon/> Microphone </label>
                        },
                        ...audioInputDevicesMenu,
                        {
                            disabled:true,
                            label:<label><SpeakerOutlinedIcon/> Speaker</label>
                        },
                        ...audioOutputDevicesMenu
                        ]} title='Manage audio devices'/>
                </Grid>
            </Grid>
        </div>
    );
}

