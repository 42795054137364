import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, colors } from "@mui/material";
import { toast } from 'react-toastify';
import axios from 'axios';
import GaugeComponent from 'react-gauge-component';

const PREFIX = 'SpeedTest';

const classes = {
  modalFormButton: `${PREFIX}-modalFormButton`,
  gauge: `${PREFIX}-gauge`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: {palette,mode}
}) => ({
  [`& .${classes.modalFormButton}`]: {
    color: palette.primary[mode],
    borderColor: palette.primary[mode],
    '&:hover': {
      borderColor: palette.info[mode],
    },
    '&.Mui-focused': {
      borderColor: palette.info[mode],
    },
    '&:disabled': {
      color: palette.disabled[mode],
    }
  },

  [`& .${classes.gauge}`]: {
    "& .outerSubArc": {
      display: "none"
    },
    "& g.value-text":{
      "& text": {
        textShadow: "none !important",
        fill: palette.color[mode] + " !important",
      }
    }
  }
}));

export const SpeedTest = ({type}) => {
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const downloadSpeedRef = useRef(null)
  downloadSpeedRef.current = downloadSpeed
  const [uploadSpeed, setUploadSpeed] = useState(null);
  const uploadSpeedRef = useRef(null)
  uploadSpeedRef.current = uploadSpeed
  const [ping, setPing] = useState(null);
  const pingRef = useRef(null)
  pingRef.current = ping
  const [testingPing, _setTestingPing] = useState(false)
  const testingPingRef = useRef(false);
  const setTestingPing = (newVal) => {
    _setTestingPing(newVal)
    testingPingRef.current = newVal
  }
  const [testingDownload, _setTestingDownload] = useState(false)
  const testingDownloadRef = useRef(false);
  const setTestingDownload = (newVal) => {
    _setTestingDownload(newVal)
    testingDownloadRef.current = newVal
  }
  const [testingUpload, _setTestingUpload] = useState(false)
  const testingUploadRef = useRef(false);
  const setTestingUpload = (newVal) => {
    _setTestingUpload(newVal)
    testingUploadRef.current = newVal
  }
  
  let downloadCancelSource;
  let uploadCancelSource;

  useEffect(() => {
      setDownloadSpeed(null)
      setUploadSpeed(null)
      setPing(null)
    return () => {
      uploadCancelSource && uploadCancelSource.cancel()
      downloadCancelSource && downloadCancelSource.cancel()
      setTestingPing(false);
      setTestingDownload(false);
      setTestingUpload(false);
    }
  }, [])

  const speedTest = async () => {
    setTestingPing(true)
    setPing(null)
    setTestingDownload(true)
    setDownloadSpeed(null)
    setTestingUpload(true)
    setUploadSpeed(null)

    let pingInterval;
    let pingTimeout;
    try{
      let pingArr = [];
      pingTimeout = setTimeout(() => {
        setTestingPing(false)
        console.alert(132611, "ping", pingRef.current)
      }, 5 * 1000);
      pingInterval = setInterval(() => {
        if(pingArr.length) {
          let lastElements = pingArr.slice(-10)
          let sum = lastElements.reduce((prev, curr) => prev + curr, 0)
          setPing(parseInt(sum / lastElements.length))
        }
      }, 200);
      setPing(0)
      while(testingPingRef.current) {
        pingArr.push(await testPing())
      } 
    } catch (err) {
      console.error(7612, "Ping test error", err)
      toast.error("Error while testing network latency: " + err.message, {autoClose: 10 * 1000})
      setTestingPing(false)
    } finally {
      clearInterval(pingInterval)
      clearTimeout(pingTimeout)
    }

    let downloadInterval;
    let downloadTimeout;
    try{
      let downloadArr = [];
      downloadTimeout = setTimeout(() => {
        downloadCancelSource.cancel();
        setTestingDownload(false)
        console.alert(132615, "download", downloadSpeedRef.current)
      }, 15 * 1000);
      setDownloadSpeed(0)
      downloadInterval = setInterval(() => {
        if(downloadArr.length) {
          let lastElements = downloadArr.slice(-20)
          let sum = lastElements.reduce((prev, curr) => prev + curr, 0)
          setDownloadSpeed(((sum / lastElements.length) / 128).toFixed(1)) // convert to Mb/s
        }
      }, 200);
      while(testingDownloadRef.current) {
        await testDownloadSpeed(downloadArr)
      } 
    } catch (err) {
      console.error(7615, "Download test error", err)
      toast.error("Error while testing download speed: " + err.message, {autoClose: 10 * 1000})
      setTestingDownload(false)
    } finally {
      clearInterval(downloadInterval)
      clearTimeout(downloadTimeout)
    }

    let uploadInterval;
    let uploadTimeout;
    try{
      let uploadArr = [];
      const uploadBuffer = new ArrayBuffer(1024 * 1024 * 32);
      const uploadData = new FormData();
      const uploadBlob = new Blob([uploadBuffer], {type : 'multipart/form-data'});
      uploadData.append('data', uploadBlob);

      uploadTimeout = setTimeout(() => {
        uploadCancelSource.cancel();
        setTestingUpload(false)
        console.alert(132617, "upload", uploadSpeedRef.current)
      }, 15 * 1000);

      uploadInterval = setInterval(() => {
        if(uploadArr.length) {
          let lastElements = uploadArr.slice(-20)
          let sum = lastElements.reduce((prev, curr) => prev + curr, 0)
          setUploadSpeed(((sum / lastElements.length) / 128).toFixed(1)) // convert to Mb/s 
        }
      }, 200);
      setUploadSpeed(0) 
      while(testingUploadRef.current) {
        await testUploadSpeed(uploadArr, uploadData)
      }
    } catch (err) {
      console.error(7618, "Upload test error", err)
      toast.error("Error while testing upload speed: " + err.message, {autoClose: 10 * 1000})
      setTestingUpload(false)
    } finally {
      clearInterval(uploadInterval)
      clearTimeout(uploadTimeout)
    }

    toast.info("Network test finished.")
  }

  const testPing = async () => {
    try{
    let startTime = Date.now();
    await axios.get(process.env.REACT_APP_SPEED_TEST_URL + "/ping")
    const duration = Date.now() - startTime;
    return duration;
    } catch (err) {
      throw err;
    }
  }

  const testDownloadSpeed = async (downloadArr) => {
    let startTime = Date.now();
    let startLoaded = 0;

    downloadCancelSource = axios.CancelToken.source();
    try {
      await axios.get(process.env.REACT_APP_SPEED_TEST_URL + "/download", {
        responseType: 'blob',
        cancelToken: downloadCancelSource.token,
        onDownloadProgress: (progressEvent) => {
          const nowTime = Date.now();
          const duration = nowTime - startTime;
          startTime = nowTime;
          
          const nowLoaded = progressEvent.loaded
          const loaded = nowLoaded - startLoaded;
          startLoaded = nowLoaded;

          const speedInMs = loaded / duration
          downloadArr.push(speedInMs)
        }
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        throw err;
      }
    }
  };

  // const runMultipleTestUploadSpeed = async (uploadArr, uploadData) => {
  //   return new Promise((resolve) => {
  //     let promises = [];

  //     promises.push(testUploadSpeed(uploadArr, uploadData))
  //     setTimeout(() => {
  //       promises.push(testUploadSpeed(uploadArr, uploadData))
  //     }, 500);
  //     setTimeout(() => {
  //       promises.push(testUploadSpeed(uploadArr, uploadData))
  //       resolve(Promise.all(promises))
  //     }, 1000);
  //   })
  // }

  const testUploadSpeed = async (uploadArr, uploadData) => {
    let startTime = new Date().getTime();
    let prevLoaded = 0;

    uploadCancelSource = axios.CancelToken.source();
    try {
      await axios.post(process.env.REACT_APP_SPEED_TEST_URL + "/upload", uploadData, {
        cancelToken: uploadCancelSource.token,
        onUploadProgress: (progressEvent) => {
          const nowTime = Date.now();
          const duration = nowTime - startTime;
          startTime = nowTime;

          const nowLoaded = progressEvent.loaded
          const loaded = nowLoaded - prevLoaded;
          prevLoaded = nowLoaded;

          const speedInMs = loaded / duration
          uploadArr.push(speedInMs)
        }
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        throw err;
      }
    }
  };

  return (
    <Root>
      <div className='row'>
        {ping !== null ?
          <div className={type === "reportIssue" ? 'col-md-4 p-0' : 'col-6 p-0'}>
            <GaugeComponent  
              className={classes.gauge}
              arc={{
                nbSubArcs: 150,
                colorArray: [colors.green[500]],
                width: 0.1,
              }}
              labels={{
                valueLabel: {
                  style: {
                    fontSize: "30px",
                  },
                  formatTextValue: () => (ping + " Ms")
                },
                tickLabels: {
                  type: "outer",
                  ticks: [
                    { value: 50 },
                    { value: 100 },
                    { value: 150 },
                  ],
                  defaultTickValueConfig: {style: {fontSize: "13px"}}
                }
              }}
              value={ping}
              maxValue={200}
            />
            <div className='text-center w-100'>
              Ping
            </div>
          </div> : ""
        }
        {downloadSpeed !== null ?
          <div className={type === "reportIssue" ? 'col-md-4 p-0': 'col-6 p-0'}>
            <GaugeComponent
              className={classes.gauge}
              arc={{
                nbSubArcs: 150,
                colorArray: [colors.blue[500]],
                width: 0.1,
              }}
              labels={{
                valueLabel: {
                  style: {
                    fontSize: "40px",
                  },
                  formatTextValue: () => (downloadSpeed + " Mb/s")
                },
                tickLabels: {
                  type: "outer",
                  ticks: [
                    { value: 8 },
                    { value: 16 },
                    { value: 32 },
                    { value: 64 },
                  ],
                  defaultTickValueConfig: {style: {fontSize: "13px"}}
                }
              }}
              value={downloadSpeed}
              maxValue={128}
            />
            <div className='text-center w-100'>
              Download
            </div>
          </div> : ""
        }
        {uploadSpeed !== null ?
          <div className={type === "reportIssue" ? 'col-md-4 p-0' : 'col-6 p-0'}>
            <GaugeComponent  
              className={classes.gauge}
              arc={{
                nbSubArcs: 150,
                colorArray: [colors.red[500]],
                width: 0.1,
              }}
              labels={{
                valueLabel: {
                  style: {
                    fontSize: "40px",
                  },
                  formatTextValue: () => (uploadSpeed + " Mb/s")
                },
                tickLabels: {
                  type: "outer",
                  ticks: [
                    { value: 8 },
                    { value: 16 },
                    { value: 32 },
                    { value: 64 },
                  ],
                  defaultTickValueConfig: {style: {fontSize: "13px"}}
                }
              }}
              value={uploadSpeed}
              maxValue={128}
            />
            <div className='text-center w-100'>
              Upload
            </div>
          </div> : ""
        }
      </div>
      <Button fullWidth className={classes.modalFormButton + " mt-2"} variant='outlined' onClick={speedTest} 
        disabled={testingPing || testingDownload || testingUpload}>
        {
          (testingPing || testingDownload || testingUpload)
          ? "Please wait for all three tests..." 
          : "Test network speed"
        }
      </Button>
    </Root>
  );
}