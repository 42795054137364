import Storages from '../constants/storages';
import apiService from './api';
import axios from 'axios';
import tokenService from './tokenService';

const API_URL = process.env.REACT_APP_API_URL;

export default async function() {
  try{
    const localCheckRes = await window.electron.checkSharedFiles();
    if(localCheckRes.code !== 0) {
      return localCheckRes;
    }

    const apiCheckRes = await apiService.checkSharedFiles({files: localCheckRes.data});
    const apiCheckData = apiCheckRes.data
    if(apiCheckData.code !== 0) {
      return apiCheckData
    }

    const {deleteArr, newArr} = apiCheckData.data;
    for(let deleteOne of deleteArr) {
      await window.electron.deleteSharedFile(deleteOne)
    }
    let token = tokenService.get();
    for(let newOne of newArr) {
      const url = API_URL + `desktop/shared-files/${newOne.id}?token=${token}`
      const response = await axios.get(url, { responseType: 'arraybuffer' })
      await window.electron.saveSharedFile(Buffer.from(response.data), newOne.name)
    }

    return {code: 0}
  } catch (err) {
    return { code: -99, msg: err.message }
  }
}