import { useEffect } from 'react';

function useBeforeUnload() {
  useEffect(() => {
    window.unloadProgrammaticallyTriggered = false;

    if(!window.electron) {
      window.addEventListener('beforeunload', (event) => {
        // Jitsi has it's own beforeunload that will leave the room. We don't want that to be called
        // We will handle leaving the room ourself
        event.stopImmediatePropagation() 
        if (window.unloadProgrammaticallyTriggered || process.env.NODE_ENV === "development") {
          window.unloadProgrammaticallyTriggered = false;
          event.returnValue = ''
          return '';
        } else {
          const confirmationMessage = 'Are you sure you want to leave?';
          event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
          return confirmationMessage; // Gecko, WebKit, Chrome <34
        }
      });
    }

    /**
     * To call window.location.replace but ignore beforeunload's warning
     * @param {String} url 
     */
    window.location.replaceWithoutWarn = function(url) {
      window.unloadProgrammaticallyTriggered = true;
      window.location.replace(url);
    };
  }, []);
}

export default useBeforeUnload;
