import React, { Component } from 'react';
import { Switch, Redirect, Route, HashRouter } from 'react-router-dom';

import {
    Login,
    FirstPage,
    Doctor,
    MainLocationPage,
    OnLoad,
    Away,
    AlreadyConnected
} from './Pages'

class Routes extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <HashRouter>
                <Switch>
                    {/* <Redirect exact from="/" to={"/on-load"} /> */}
                    <Route exact path="/first-page" component={FirstPage} />
                    {/* <Route exact path="/on-load" component={OnLoad} /> */}
                    <Route exact path="/login" component={() => <Login {...this.props} />} />
                    <Route exact path="/reset-password" component={() => <Login {...this.props} />} />
                    <Route exact path="/receptionist" component={() => <Doctor {...this.props} />} />
                    <Route exact path="/mainlocationpage/:username/:clientid" component={() => <MainLocationPage {...this.props} />} />
                    <Route exact path="/location/away" component={() => <Away {...this.props} />} />
                    <Route exact path="/already-connected" component={() => <AlreadyConnected/>} />
                    <Redirect exact from="*" to={"/"} />
                </Switch>
            </HashRouter>
        )
    }
}

export default Routes;