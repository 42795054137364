import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Button,colors} from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import './modal-style.css'

export const Confirm = forwardRef((props, ref) => {
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  // const [buttons, setButtons] = useState([]);
  const [inputButtons, setInputButtons] = useState([]);
  const [resolveFn, setResolveFn] = useState(null);
  const [open, setOpen] = useState(false);
  const [size, setSize]=useState('lg')
  useImperativeHandle(ref, () => ({
    show(inputTitle, inputMsg, inputButtons, size) {
      setTitle(inputTitle)
      setMsg(inputMsg)
      setOpen(true)
      setSize(size)
      setInputButtons(inputButtons)
      // let buttons = [];
      // const done = new Promise((resolve, reject) => {
      //   for(let inputButton of inputButtons) {
      //     buttons.push(

      //     )
      //   }
      // })
      // setButtons(buttons)
      return new Promise((resolve) => {
        setResolveFn(() => resolve)
      });
    },
    hide() {
      setOpen(false)
    }
  }));

  const handleButtonClicked = (value) => {
    if(resolveFn) {
      resolveFn(value)
      setResolveFn(null)
    }
  }
  
  return (
    <div>
      <Modal show={open} size={size} fullWidth={true} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          {title}
        </Modal.Header>
        <Modal.Body>
          {msg}
        </Modal.Body>
        <Modal.Footer>
          {inputButtons.map((inputButton) => 
            <Button 
              className={"modal-btn-confirm " + inputButton.color?'modal-btn-'+inputButton.color: ''}
              onClick={() => {
                handleButtonClicked(inputButton.value);
                if(inputButton.close) {
                  setOpen(false)
                }
              }} 
            >
              {inputButton.text}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
})