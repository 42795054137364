import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';
import React  from 'react';
import {
  Paper,InputBase,IconButton,CircularProgress,Avatar,CardActions,
  Card, CardContent, CardHeader, ButtonBase, Button, Tooltip,LinearProgress
} from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import apiService from "../../services/api"
import {useSelector, useDispatch } from 'react-redux';
import { addMessage, setMessages, setUnReadMessages } from '../../redux/messages';
import { toast } from 'react-toastify';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import tokenService from '../../services/tokenService';
import axios from 'axios';
import  './styleReception.css'
const PREFIX = 'Messanger';

const classes = {
  btnClose: `${PREFIX}-btnClose`,
};

const CardHeaderStyled = styled(CardHeader)(({
  theme: {palette,mode}
}) => ({
  backgroundColor: palette.bgMessanger[mode],
  padding: '5px',
  '& .MuiCardHeader-title':{
      color:palette.colorMessanger[mode],
      fontSize: "14.5px",
      textTransform: "capitalize"
  },
  '& .MuiCardHeader-action':{
    marginTop: "4px",
    marginRight: "0px"
  },
  [`& .${classes.btnClose}`]: {
      border:0,
      borderRadius:0,
      padding:0,
      color:palette.colorMessanger[mode],
  }
}));

const API_URL = process.env.REACT_APP_API_URL;
let token;
export default ({display,handleCloseChat,reception,displayName})=>{

    const [msg,setMsg]=useState('')
    const dispatch=useDispatch()
    const messages = useSelector((state) => state.messages.all[reception])
    const [listMessages,setListMessages]=useState([])
    const messagesEndRef=useRef(null)
    const [spinner,setSpinner]=useState(false)
    const [limit,setLimit]=useState(100)
    const [lastMessageID,setLastMessageID]=useState(false) 
    const [firstLoad,setFirstLoad]=useState(false) 
    const inputRef=useRef()
    const [reply, setReply]=useState({show: false, id: 0, msg: ""}) 
    const [downloads, setDownloads] = useState([])
    const downloadsRef = useRef();
    downloadsRef.current = downloads;
    const [uploads, setUploads] = useState([])
    const uploadsRef = useRef();
    uploadsRef.current = uploads;

    // const [attachment, setAttachment] = useState({
    //   show: false, 
    //   file: null, 
    //   isUploading: false, 
    //   uploadingPercent: 0,
    //   error: "",
    // }) 
    
    useEffect(()=>{
        if(!messages || !messages[0]) return  setListMessages([])
        var date;
        const list=[]
        var firstUnRead=false
        for(var msg of messages){
            const {
              send_at,
              message,
              id,
              read_at,
              parentID,
              parentMsg,
              parentSender,
              parentFilename,
              fileID,
              filePath,
              filename,
              fileMimetype,
            } = msg;
            var msgDate=send_at.slice(0,10)
            var time=send_at.slice(11,16)
            let parent;
            let file;
            if(parentID) {
              parent= {
                id: parentID, 
                msg: parentMsg, 
                sender: parentSender,
                filename: parentFilename,
              }
            }
            if(fileID) {
              file = {
                id: fileID,
                path: filePath,
                name: filename,
                mimetype: fileMimetype,
              }
            }
            if(date!=msgDate) {
              list.push(lineDate(msgDate))
            }
            if(msg.sender==reception){
              if(!read_at && !firstUnRead && firstLoad) {
                // scroll to this msg
                list.push(lineDate('Unread'))
                setLastMessageID("Unread")
                firstUnRead=true
              }
              list.push(messageElement(message, time, id, parent, file, "messanger-receive"))
            }
            if(msg.receiver==reception) {
              list.push(messageElement(message, time, id, parent, file, "messanger-send"))
            }
            date=msgDate
        }
        setListMessages(list)
    },[messages])
    
    useEffect(()=>{
        if(display){
            setLimit(100)
            setSpinner(true)
            getMessages()
            setFirstLoad(true)
            inputRef.current.focus()
        }
    }, [reception])
    
    useEffect(()=>{
        setFirstLoad(true)
    }, [display])

    useEffect(()=>{
        setSpinner(false)
        setTimeout(() => {
            scrollToBottom()
        }, 20);
    }, [listMessages])

    useEffect(() => {
      token = tokenService.get()
      if(window.electron) {
        window.electron.onDownloadChatAttachmentProgress((event, data) => {
          onDownloadProgress(data)
        })
      }
    }, [])
    
    const onDownloadProgress = async (data) => {
      if(data.progress) {
        let tmpDownloads = [...downloadsRef.current]
        let downloadItem = tmpDownloads.find(item => item.msgID === data.progress.msgID)
        if(downloadItem) {
          downloadItem.percent = data.progress.percent
        } else {
          tmpDownloads.push({
            msgID: data.progress.msgID,
            filename: data.progress.filename,
            percent: 0,
            cancelToken: data.progress.cancelToken,
          });
        }
        setDownloads(tmpDownloads)
      } else if(data.done) {
        let tmpDownloads = [...downloadsRef.current]
        tmpDownloads = tmpDownloads.filter(item => item.msgID !== data.done.msgID)
        setDownloads(tmpDownloads)
        toast.success(<><i>{data.done.filename}</i> saved successfully!</>, {autoClose: 3000})
      } else if (data.error) {
        let tmpDownloads = [...downloadsRef.current]
        tmpDownloads = tmpDownloads.filter(item => item.msgID !== data.error.msgID)
        setDownloads(tmpDownloads)
        toast.error(<>Error while downloading <i>{data.error.filename}</i>: {data.error.err.message}</>, {autoClose: 10 * 1000})
      } else if (data.cancel) {
        let tmpDownloads = [...downloadsRef.current]
        tmpDownloads = tmpDownloads.filter(item => item.msgID !== data.cancel.msgID)
        setDownloads(tmpDownloads)
        toast.info("Download canceled by user.", {autoClose: 5000});
      }
    }

    const onUploadProgress = async (data) => {
      if(data.progress) {
        let tmpUploads = [...uploadsRef.current]
        let uploadItem = tmpUploads.find(item => item.msgID === data.progress.msgID)
        if(uploadItem) {
          uploadItem.percent = data.progress.percent
        } else {
          tmpUploads.push({
            msgID: data.progress.msgID,
            filename: data.progress.filename,
            percent: 0,
            cancelToken: data.progress.cancelToken,
          });
        }
        setUploads(tmpUploads)
      } else if(data.done) {
        let tmpUploads = [...uploadsRef.current]
        tmpUploads = tmpUploads.filter(item => item.msgID !== data.done.msgID)
        setUploads(tmpUploads)
        toast.success(<><i>{data.done.filename}</i> uploaded successfully!</>, {autoClose: 3000})
      } else if (data.error) {
        let tmpUploads = [...uploadsRef.current]
        tmpUploads = tmpUploads.filter(item => item.msgID !== data.error.msgID)
        setUploads(tmpUploads)
        toast.error(<>Error while uploading <i>{data.error.filename}</i>: {data.error.err.message}</>, {autoClose: 10 * 1000})
      } else if (data.cancel) {
        let tmpUploads = [...uploadsRef.current]
        tmpUploads = tmpUploads.filter(item => item.msgID !== data.cancel.msgID)
        setUploads(tmpUploads)
        toast.info("Upload canceled by user.", {autoClose: 5000});
      }
    }
    
    const getMessages=async(n)=>{
      try{
        if(!n) n=limit
        if(!reception)return
        var res=await apiService.getMessages({reception, limit:n})
        if(res.data.code !== 0) {
          toast.error(res.data.msg, {autoClose: 10 * 1000})
          return
        }
        const all=res.data.data
        dispatch(setMessages({reception,messages:all}))
        dispatch(setUnReadMessages([{username:reception,messages:all}]))
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const handleSubmitInput=async()=>{
      try{
        var msg=inputRef.current.value
        if(!msg || !msg.trim()) return
        const data={
            to:reception,
            msg:msg.trim()
        }
        if(reply && reply.show && reply.id) {
          data.parentID = reply.id
        }
        var res=await apiService.chat(data)
        if(res.data.code !== 0) {
          toast.error(res.data.msg, {autoClose: 10 * 1000})
          return
        } 

        var message = res.data.data
        // add to redux (all messages)
        setFirstLoad(false)
        dispatch(addMessage({reception,message}))

        if(messagesEndRef && messagesEndRef.current && messagesEndRef.current.lastElementChild){
            messagesEndRef.current.lastElementChild.scrollIntoView({block: 'end'});
        }

        inputRef.current.value=''
        setMsg('')
        hideReply()
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10 * 1000})
      }
    }
    const handleOnKeyDown=(e)=>{
        if(e.key === 'Enter') handleSubmitInput()
    }
    const messageElement = (msg, time, id, parent, file, cls) => {
      if(parent) {
        if(parent.filename) {
          parent.filename = parent.filename.length > 30 ? parent.filename.substr(0, 30) + "..." : parent.filename
        }
        if(parent.msg) {
          parent.msg = parent.msg.length > 30 ? parent.msg.substr(0, 30) + "..." : parent.msg
        }
        parent.sender = parent.sender.length > 30 ? parent.sender.substr(0, 30) + "..." : parent.sender
      }
      return (
        <div
          id={"msg_" + id}
          key={id}
          style={{
            width: "100%",
            transition: "background-color 0.5s",
            borderRadius: "3px",
          }}
        >
          <div className={cls}>
            {parent && (
              <span
                className="pl-2 mx-1 my-2"
                onClick={() => scrollToMsg(parent.id)}
                style={{ borderLeft: "2px solid #3f51b5", cursor: "pointer" }}
              >
                <b style={{ color: "#3f51b5" }}>{parent.sender}</b>
                <br />
                {parent.filename || parent.msg}
              </span>
            )}
            {file && (
              <span>
                <a
                  href="javascript: void(0)"
                  onClick={() =>
                    download(id, {
                      path: `${API_URL}desktop/messages/attachment/${id}?token=${token}`,
                      name: file.name,
                    })
                  }
                >
                  {file.name}
                </a>
              </span>
            )}
            {msg && <span>{msg}</span>}
            <div>
              <div className="float-right d-flex align-items-center">
                <ButtonBase
                  className="pr-1"
                  onClick={() => showReply(id, msg, file && file.name)}
                  title="Reply to this message"
                >
                  <ReplyIcon
                    fontSize="inherit"
                    style={{ fontSize: "18px", cursor: "pointer" }}
                    color="primary"
                  />
                </ButtonBase>
                <small>{time}</small>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const showReply = (parentID, parentMsg, parentFilename) => {
      setReply({show: true, id: parentID, msg: parentFilename || parentMsg})
      document.getElementById("messagesInput").focus()
    }
    const hideReply = () => {
      setReply({show: false, id: 0, msg: ""})
    }
    const scrollToMsg = (id) => {
      var el=document.getElementById("msg_" + id)
      if(el) {
        el.scrollIntoView({ behavior: 'smooth'})
        el.style.backgroundColor = '#6f81f5';
        setTimeout(() => {
          el.style.backgroundColor = '';
        }, 3000);
      } else {
        
      }
    }
    const lineDate=(date)=>{
        return (
            <div id={"msg_"+date.replaceAll(' ','-')} className='messanger-date' key={date.replaceAll(' ','-')}>
                <small><b>{date}</b></small>
            </div>
        )
    }
    
    const scrollToBottom = () => {
        if(lastMessageID) {
            var el=document.getElementById("msg_" + lastMessageID)
            if(el) el.scrollIntoView()
            setLastMessageID('')
            return
        }
        if(messagesEndRef && messagesEndRef.current){
            const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
            if(firstLoad){
                if(messagesEndRef.current.lastElementChild){
                    messagesEndRef.current.lastElementChild.scrollIntoView();
                }
            }
            else if (scrollTop + clientHeight + 100 > scrollHeight) { // bottom of the chat room
                if(messagesEndRef.current.lastElementChild)
                    messagesEndRef.current.lastElementChild.scrollIntoView({block: 'end'});
            }
        }  
    }

    const openAttachment = () => {
      document.getElementById("messagesAttachmentInput").click()
    }

    const confirmUpload = (event) => {
      const input = document.getElementById("messagesAttachmentInput")
      if(!input.files || !input.files[0]) {
        return
      }

      if(input.files[0].size > 100 * 1024 * 1024) {
        toast.error("The file is too big. Maximum size is 100 MB.")
        return
      }

      // setAttachment({show: true, file: input.files[0], isUploading: false, uploadingPercent: 0, error: ""})
      window.confirmAsync.show(
        <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Confirm</h6>, 
        <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
          Send <i>{input.files[0].name}</i> to <i>{reception}</i>?
        </span>, 
        [
          { value: 1, color: "primary", text: "Yes", close: 1 },
          { value: 0, color: "default", text: "No", close: 1 },
        ]
      ).then(async (value)=>{
        if(value === 1){
          let msgID = Date.now()
          upload(input.files[0], msgID)
        } else {
          document.getElementById("messagesAttachmentInput").value = ""
          // setAttachment({show: false, file: null, isUploading: false, uploadingPercent: 0, error: ""})
        }
      })
    }

    const upload = async (file, msgID) => {
      try{
        const cancelToken = axios.CancelToken.source();
        // if(!attachment.file) {
        //   setAttachment({...attachment, error: "Nothing selected!"});
        //   return
        // }

        const uploaded = await apiService.uploadChatAttachment({
          reception, 
          file,
          parentID: reply && reply.show ? reply.id : ""
        }, (progress) => {
          setTimeout(() => { // so it won't block other browser jobs
            let percent = Math.round((progress.loaded * 100) / progress.total);
            onUploadProgress({progress: {msgID, filename: file.name, percent, cancelToken}})
          }, 0);
        }, cancelToken.token)

        if(uploaded.data.code === 0) {
          var message = uploaded.data.data
          // add to redux (all messages)
          setFirstLoad(false)
          dispatch(addMessage({reception,message}))

          if(messagesEndRef && messagesEndRef.current && messagesEndRef.current.lastElementChild){
            messagesEndRef.current.lastElementChild.scrollIntoView({block: 'end'});
          }
          // because we have sat a timeout for axios progress
          setTimeout(() => {
            onUploadProgress({done: {msgID, filename: file.name}})
          }, 5);
          // setAttachment({show: false, file: null, isUploading: false, uploadingPercent: 0, error: ""})
          hideReply()
        } else {
          onUploadProgress({error: {msgID, filename: file.name, err: {message: uploaded.data.msg}}})
          // setAttachment({...attachment, isUploading: false, uploadingPercent: 0, error: uploaded.data.msg})
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          onUploadProgress({cancel: {msgID, filename: file.name}})
        } else {
          console.error(err)
          onUploadProgress({error: {msgID, filename: file.name, err}})
          // setAttachment({...attachment, isUploading: false, uploadingPercent: 0, error: err.message})
        }
      }
    }

    const download = async (msgID, file) => {
      if(window.electron) {
        try{
          const downloadRes = await window.electron.downloadChatAttachment(msgID, file);
          if(downloadRes.code !== 0) {
            toast.error(downloadRes.msg, {autoClose: 5000})
          }
        } catch (err) {
          console.error(err)
          toast.error(err.message, {autoClose: 5000})
        }
      } else {
        const filename = file.name
        const cancelToken = axios.CancelToken.source();
        try{
          const res = await axios.get(`${API_URL}desktop/messages/attachment/${msgID}?token=${token}`, {
            responseType: 'blob', 
            onDownloadProgress: (progressEvent) => {
              setTimeout(() => { // so it won't block other browser jobs
                let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                onDownloadProgress({progress: {msgID, filename, percent, cancelToken}})
              }, 0);
            },
            cancelToken: cancelToken.token
          })
          // create file link in browser's memory
          const href = URL.createObjectURL(res.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', filename); //or any other extension
          link.click();

          URL.revokeObjectURL(href);

          // because we have sat a timeout for axios progress
          setTimeout(() => {
            onDownloadProgress({done: {msgID, filename}})
          }, 5);
        } catch (err) {
          if (axios.isCancel(err)) {
            onDownloadProgress({cancel: {msgID, filename: file.name}})
          } else {
            console.error(err)
            onDownloadProgress({error: {msgID, filename: file.name, err}})
            // setAttachment({...attachment, isUploading: false, uploadingPercent: 0, error: err.message})
          }
        }
      }
    }

    const cancelUpload = (uploadItem) => {
      if(uploadItem.cancelToken) {
        window.confirmAsync.show(
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Confirm</h6>, 
          <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
            Cancel uploading <i>{uploadItem.filename}</i>?
          </span>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 },
            { value: 0, color: "default", text: "No", close: 1 },
          ]
        ).then(async (value)=>{
          if(value === 1){
            const found = uploadsRef.current.find(item => item.msgID === uploadItem.msgID)
            if(found) {
              uploadItem.cancelToken.cancel();
            }
          }
        })
      }
    }

    const cancelDownload = (downloadItem) => {
      if(downloadItem.cancelToken) {
        window.confirmAsync.show(
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Confirm</h6>, 
          <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
            Cancel downloading <i>{downloadItem.filename}</i>?
          </span>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 },
            { value: 0, color: "default", text: "No", close: 1 },
          ]
        ).then(async (value)=>{
          if(value === 1){
            const found = downloadsRef.current.find(item => item.msgID === downloadItem.msgID)
            if(found) {
              downloadItem.cancelToken.cancel();
            }
          }
        })
      }
    }

    const handleScroll=(e)=>{
        let element = e.target
        if (element.scrollTop == 0) {
            if(messages && messages[0]) setLastMessageID(messages[0].id)
            var n=limit+100
            setLimit(n)
            getMessages(n)
        }
    }

    if(display){
        return(
          <>
            {/* {attachment.show && <Modal show={true} onHide={closeAttachmentModal} centered>
              <Modal.Header closeButton>
                  <Modal.Title>Attachment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Send "{attachment.file.name}" to "{reception}"?
                {attachment.error && <div style={{color: "red"}}>Error: {attachment.error}</div> }
              </Modal.Body>
              {attachment.isUploading?<LinearProgress variant="determinate" value={attachment.uploadingPercent} />:""}
              <Modal.Footer>
                <Button disabled={attachment.isUploading} 
                  variant="contained" onClick={closeAttachmentModal}>
                  Cancel
                </Button>
                <Button color="primary" disabled={attachment.isUploading} 
                  variant="contained" onClick={submitAttachment}>
                  Send
                </Button>
              </Modal.Footer>
            </Modal>} */}
            <Card className='messanger-card' >
                <CardHeaderStyled 
                    title={displayName || reception}
                    action={
                        <IconButton className={classes.btnClose} onClick={handleCloseChat} aria-label="close">
                            <CloseOutlinedIcon />
                        </IconButton>
                    }
                    avatar={
                        <Avatar aria-label="recipe" className='messanger-avatar'>
                            {(displayName && displayName[0]?.toUpperCase()) || (reception && reception[0]?.toUpperCase())}
                        </Avatar>
                        }
                />
                {
                  downloads.map(item => 
                    <Tooltip key={item.msgID} onClick={() => !window.electron && cancelDownload(item)}
                      title={`Downloading ${item.filename}: ${item.percent}%.` + (!window.electron ? " Click to cancel" : "")}>
                      <LinearProgress variant="determinate" style={{height: "10px", cursor: "pointer"}} 
                        value={item.percent} />
                    </Tooltip>
                  )
                }
                {
                  uploads.map(item => 
                    <Tooltip key={item.msgID} onClick={() => cancelUpload(item)}
                      title={`Uploading ${item.filename}: ${item.percent}%. Click to cancel`}>
                      <LinearProgress variant="determinate" style={{height: "10px", cursor: "pointer"}}
                        className='messanger-mirrored-progress' value={item.percent} />
                    </Tooltip>
                  )
                }
                <CardContent className='messanger-content' onScroll={handleScroll} ref={messagesEndRef}>
                    {spinner?
                    <div className='messanger-spinner'>
                        <CircularProgress size={'2rem'}/>
                    </div>
                    :listMessages}
                </CardContent>
                <CardActions className='messanger-action'>
                    <Paper className='d-flex flex-column w-100'>
                      {reply.show && 
                        <div className="p-1" style={{backgroundColor: "#fff", height: "30px"}} 
                          title="You are writing a reply to this message">
                          <ReplyIcon fontSize="inherit" className='float-left' style={{fontSize: "20px"}} color="primary"/>
                          <span onClick={() => scrollToMsg(reply.id)} className='px-1 text-truncate d-inline-block' 
                            style={{maxWidth: "80%", cursor: "pointer"}}>
                            {reply.msg}
                          </span>
                          <ButtonBase className='float-right' onClick={hideReply} title='Remove this reply'>
                            <CloseIcon fontSize="inherit" style={{fontSize: "20px"}}/>
                          </ButtonBase>
                        </div>
                      }
                      <div className='messanger-paper'>
                        {msg.length == 0 ? <IconButton className='messanger-icon-button' onClick={openAttachment}>
                          <AttachFileIcon />
                        </IconButton> : <span className='px-1'></span>}
                        <InputBase
                            autoFocus 
                            className='messanger-input'
                            placeholder="Type..."
                            inputRef={inputRef}
                            onKeyDown={handleOnKeyDown}
                            id="messagesInput"
                        />
                        {/* <Divider className="messanger-divider" orientation="vertical" /> */}
                        <IconButton className='messanger-icon-button' onClick={handleSubmitInput} aria-label="submit">
                            <SendOutlinedIcon />
                        </IconButton>
                      </div>
                    </Paper>
                </CardActions>
                <input style={{display: "none"}} type='file' id="messagesAttachmentInput" onChange={confirmUpload}/>
            </Card> 
          </>
        )
    }else return null

}