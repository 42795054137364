module.exports = {
  "sleep": "Please touch this screen to get started",
  "welcomeTo": "Welcome to [[locationName]]",
  "welcomeMakeSelection": "Please make a selection below",
  "haveAppointmentQuestion": "Do you have an appointment today?",
  "chooseLanguage": "Please select your language below",
  "firstAppointmentQuestion": "Is today your first appointment\n(initial evaluation)?",
  "selfCheckInOrKiosk": "Would you like to self-check in through\nthe kiosk or receptionist?",
  "thanks": "Thank You",
  "callConnectionWait": "Please wait while we connect\nyou with a receptionist.",
  "connecting": "Connecting",
  "yes": "Yes",
  "no": "No",
  "backButton": "Back",
  "callReceptionForHelp": "call reception for help",
  "kiosk": "Kiosk",
  "reception": "Reception",
  "proceedToSelfCheckIn": "Please proceed to the Express Check-in\nKiosk to complete your check-in.",
  "cancelSelfCheckIn": "Cancel self check-in",
  "hiim": "Hi I'm:",
  "kioskTimeoutQuestion": "Do you need more time?",
  "kioskTimeout1": "The kiosk is about to close due to inactivity for [[totalSeconds]] seconds. ",
  "kioskTimeout2": "[[remainingSeconds]] seconds remaining.",
  "noInternetConnection": "It seems like there is no internet connection. Retrying…",
  "upgradingOurServices": "We are currently upgrading and updating our service. We apologize for any inconvenience this may cause. Please be patient while we apply these necessary changes, your system will be back up shortly.",
  "pleaseRateYourExperience": "Please rate your experience with your receptionist",
  "wait": "Please wait...",
  "cancel": "Cancel",
  "scanTitle": "Scan",
  "scanInsertFaceDown": "Please insert your document face down into the scanner, then press scan button.",
  "scanInsertFeeder": "Please insert your document into the scanner feeder, then press scan button.",
  "scanNoScanRequest": "No scan request! Please ask the reception to send scan request.",
  "scanUploading": "Uploading. Please wait...",
  "uploadError": "Error while uploading file. Retrying...",
  "scanSuccessful": "Your documents uploaded successfully.",
  "scanButton": "Scan",
  "cardDetectionTitle": "Card Detection",
  "cardDetectionMsg": "Please bring your card close to the camera and hold still for a few seconds.",
  "cardDetectionError": "Error while detecting ID card. Please ask reception for help.",
  "cardDetectionCloser": "A little closer!",
  "cardDetectionHoldStill": "Hold still!",
  "cardDetectionUploading": "Your card has been saved. Uploading...",
  "busyArea": "All receptionists are currently\nassisting other customers,\nplease have a seat and someone\nwill be with you shortly.",
  "serverError": "Internal server error.",
  "success": "Success!",
  "amount": "Amount",
  "description": "Description",
  "followCardTerminal": "Please follow the instructions on the credit card terminal",
  "paymentCanceled": "Payment has been canceled by reception.",
  "paymentThanks": "Thank you for your payment.",
  "paymentUnknownError": "Unknown payment error. Please ask reception for help.",
  "paymentDeclinedError": "Payment declined or had error. Please ask reception for help.",
  "paymentSendError": "Error while sending payment request. Please ask reception for help.",
  "paymentSuccessRemoveCard": "Transaction was complete but card not removed. Please remove your card.",
  "printing": "New document is printing...",
  "callEndRecOffline": "The call ended as reception disconnected",
  "mediaTrackError": "There is an error with audio or video device.Please reload and contact customer support.",
  "callEndTranToNewReception": "Please wait while your call is transferred to another reception...",
  "locationAwayMsg": "This kiosk is temporarily not being used at this location. \nPlease see the receptionist at the counter.",
}