import { createSlice } from '@reduxjs/toolkit';

export const cameraReducer = createSlice({
	name: 'cameras',
	initialState: [],
	reducers: {
		addCamera: (state, action) => {
			const camera = {
				id: state.length,
				deviceId: action.payload.deviceId,
				label: action.payload.label,
				selected: action.payload.selected,
			};
			state.push(camera);
			return state
		},
		selectCamera: (state, action) => {
			var {index,deviceId}=action.payload
			state.forEach((el) => {
				el.selected = (deviceId && el.deviceId===deviceId)?true:false;
			});
			if(index || index===0){
				const selectedIndex = state.findIndex((camera) => camera.id === action.payload.index);
				state[selectedIndex].selected = true;
			}
			return state
		},
		deleteCamera: (state, action) => {
			const updated = state.filter((camera) => camera.deviceId != action.payload.deviceId);
			const ret = updated.map((camera, index) => {
				let item = {
					id: index,
					deviceId: camera.deviceId,
					label: camera.label,
					selected: camera.selected,
				}
				return item;
			});
			return ret;
		},
		deleteCameras: () => {
			return [];
		},
	},
});

export const { addCamera, selectCamera, deleteCamera, deleteCameras } = cameraReducer.actions;

export default cameraReducer.reducer;
