import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import { HiddenMenu } from '../../Components/Location/HiddenMenu';
import ColorButton from '../../Components/Patient/ColorButton';
import Color from '../../constants/usercolor';
import PageConst from '../../constants/patientpage';
import Storages from '../../constants/storages';
import { sendLabelLocationToSocket } from '../../context/socket';

const Kiosk = (props) => {
    useEffect(() => {
      const roomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME)
        sendLabelLocationToSocket(props.match.params.username, roomName, "Kiosk")
        window.goBackToWelcomeTimeout = setTimeout(()=>{
            props.moveToPage(PageConst.WELCOME)
        }, 30 * 1000)
        return (()=>{
            sendLabelLocationToSocket(props.match.params.username, roomName, "")
            clearTimeout(window.goBackToWelcomeTimeout)
        })
    }, []);

    const handleCancel=()=>{
        props.moveToPage(PageConst.WELCOME)
    }
    return (
        <div className='root'>
            <HiddenMenu/>
            <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='center_container'>
                <label className='main_lab'>{window.i18n.getString("proceedToSelfCheckIn")}</label>
                <ColorButton onClicked={handleCancel} Color={Color.YELLOW_BTN_COLOR}
                  Title={window.i18n.getString("cancelSelfCheckIn")} Width="30vw" />
            </div>
        </div>
    )
}

Kiosk.prototype = {

}

export default withRouter(Kiosk);
