import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './modal-style.css';

export const IdleTimeOutModal = ({ showModal, handleClose, handleTimeout, remainingTime }) => {

    return (
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>Are you still there?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>We noticed you have been inactive. Please click the Stay button if you are still working.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleTimeout}>
                    Logout
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Stay
                </Button>
            </Modal.Footer>
        </Modal>
    )
}