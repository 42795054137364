import { createSlice } from '@reduxjs/toolkit';


export const Screens=createSlice({
    name:"screens",
    initialState:[],
    reducers:{
        setScreens:(state, action)=>{
            state=action.payload
            return state
        }
    }
})

export const { setScreens} = Screens.actions

export default Screens.reducer