import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css';
import ColorButton from '../../Components/Patient/ColorButton';
import BackButton from '../../Components/Patient/BackButton';
import Str from '../../constants/string';
import PageConst from '../../constants/patientpage';
import IconColorButton from '../../Components/Patient/IconColorButton';
import HairImage from '../../assets/images/hair_icon.png';
import Color from '../../constants/usercolor';
import { HiddenMenu } from '../../Components/Location/HiddenMenu';

const SelfCheck = (props) => {
    const { isCalling, moveToPage } = props;

    useEffect(() => {
      window.goBackToWelcomeTimeout = setTimeout(()=>{
        moveToPage(PageConst.WELCOME)
      }, 30 * 1000)
      return (()=>{
        clearTimeout(window.goBackToWelcomeTimeout)
      })
    }, [])

    const handleKiosk = () => {
        if (isCalling) {
            return;
        }

        if(localStorage.getItem("integrated_kiosk") == "1") {
          props.handleShowCallDialog(Str.KIOSK, true);
        } else {
          props.moveToPage(PageConst.KIOSK);
        }
    }

    const handleReceptionist = () => {
        if (isCalling) {
            return;
        }
        props.handleShowCallDialog(Str.STR_SELF_CHECK, false);
    }

    const handleBack = () => {
        if (isCalling) {
            return;
        }
        props.moveToPage(PageConst.APPOINTMENT);
    }

    const handleCall = () => {
        if (isCalling) {
            return;
        }
        props.handleShowCallDialog(Str.STR_NORMAL, false);
    }

    return (
        <div className='root'>
            <HiddenMenu/>
            <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
            <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
            <div className='center_container'>
              <label className='main_lab'>{window.i18n.getString("selfCheckInOrKiosk")}</label>
              <div className='bottom_div'>
                <ColorButton onClicked={handleKiosk} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.YELLOW_BTN_COLOR} 
                  Title={window.i18n.getString("kiosk")} Width="20vw" />
                <ColorButton onClicked={handleReceptionist} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR} 
                  Title={window.i18n.getString("reception")} Width="20vw" />
              </div>
            </div>
            <div id="call_div">
                <IconColorButton onClicked={handleCall} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR} 
                  Icon={HairImage} Title={window.i18n.getString("callReceptionForHelp")} />
            </div>
            <div id="back_div">
                <BackButton onClicked={handleBack} />
            </div>
        </div>
    )
}

SelfCheck.prototype = {

}

export default withRouter(SelfCheck);
