/**
 * @param {("jaas"|""|undefined|null)} type Either pass "jaas" to connect to 8x8 servers,
 * or leave empty to connect to our jitsi servers from localStorage or .env file
 * @returns Jitsi config to pass as third argument to `window.JitsiMeetJS.JitsiConnection`
 */
export default function (type) {
  const jitsiMainDomain = localStorage.getItem("jitsiMainDomain")
  if (type === "jaas") {
    const jaasTenant = localStorage.getItem("jaasAppID")
    const room = localStorage.getItem("lucas_therapies_roomname")
    return {
      hosts: {
        domain: jitsiMainDomain,
        muc: `conference.${jaasTenant}.${jitsiMainDomain}`,
      },
      serviceUrl: `wss://${jitsiMainDomain}/${jaasTenant}/xmpp-websocket?room=${room}`,
      websocketKeepAliveUrl: `https://${jitsiMainDomain}/${jaasTenant}/_unlock?room=${room}`,
    }
  } else {
    return {
      hosts: {
        domain: jitsiMainDomain || process.env.REACT_APP_JITSI_MAIN_DOMAIN,
        muc: 'conference.' + (jitsiMainDomain || process.env.REACT_APP_JITSI_MAIN_DOMAIN), // FIXME: use XEP-0030
      },
      serviceUrl: 'https://' + (localStorage.getItem("jitsiDnsName") || process.env.REACT_APP_JITSI_DNS_NAME) + '/http-bind', // FIXME: use xep-0156 for that
      clientNode: "https://jitsi.org/jitsimeet",
      useStunTurn: true
    }
  }
}