import React from "react";
import Grid from "@mui/material/Grid";
import Storages from '../../constants/storages';
import CallTimeLabel from '../Controls/CallTimeLabel';
import './styleReception.css'

export default function ReceptionInfo (){
    const name=localStorage.getItem(Storages.LOCAL_RECEPTION_NAME)
    

    return (
        <div className='reception-info'>
            <Grid container   spacing={2} direction='row' justifyContent="center" alignItems="center">
                <Grid item className="text-capitalize">
                    {name}
                </Grid>
                <Grid item >
                    <CallTimeLabel fontSize={'1'} duringTime={0} play={true} />
                </Grid>
            </Grid>
        </div>
    );
}
 
