const storages = require("../constants/storages")
const Cookies = require("js-cookie")

module.exports = {
  get: () => {
    const role = localStorage.getItem(storages.LOCAL_ROLE);
    if(role === "location") {
      return localStorage.getItem(storages.LOCAL_TOKEN_USER)
    }
    if(role === "reception") {
      if(!window.electron)  return Cookies.get('token') || null
      const sessionToken = sessionStorage.getItem(storages.LOCAL_TOKEN_USER)
      if(sessionToken) {
        return sessionToken;
      }

      const rememberMe = localStorage.getItem(storages.LOCAL_IS_REMEMBER)
      if(rememberMe) {
        const localToken = localStorage.getItem(storages.LOCAL_TOKEN_USER)
        if(!localToken) {
          return null;
        }

        sessionStorage.setItem(storages.LOCAL_TOKEN_USER, localToken)
        return localToken;
      } else {
        return null;
      }
    } else {
      return null;
    }
  },
  set: (token) => {
    if(!token) {
      throw new Error("No token passed to tokenService.set")
    }

    const role = localStorage.getItem(storages.LOCAL_ROLE);
    const rememberMe = localStorage.getItem(storages.LOCAL_IS_REMEMBER)
    if(role === "reception") {
      if(!window.electron){
        let option=rememberMe && {expires:7}
        Cookies.set("token",token,option)
        return
      }
      if(rememberMe) {
        localStorage.setItem(storages.LOCAL_TOKEN_USER, token)
        sessionStorage.setItem(storages.LOCAL_TOKEN_USER, token)
      } else {
        sessionStorage.setItem(storages.LOCAL_TOKEN_USER, token)
      }
    } else if(role === "location") {
      localStorage.setItem(storages.LOCAL_TOKEN_USER, token)
    } else {
      return null;
    }
  },
  remove: () => {
    localStorage.removeItem(storages.LOCAL_TOKEN_USER)
    sessionStorage.removeItem(storages.LOCAL_TOKEN_USER)
    Cookies.remove("token")
  },
}