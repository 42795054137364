import React, { Component } from 'react';
import './style.css';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReactDOM from 'react-dom';
// import CallTimeLabel from '../Controls/CallTimeLabel';

export default class LocalUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_name: this.props.user_name,
            user_status: this.props.user_status,
            isDisable: true,
            isShow: false
        };
        this.handleDropClicked = this.handleDropClicked.bind(this);
        this.changeUserStatus = this.changeUserStatus.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({ isShow: false });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ user_status: nextProps.user_status });
        switch (nextProps.user_status) {
            case Constant.LOGOUT:
                this.setState({ isDisable: true });
                break;
            case Constant.AWAY:
                this.setState({ isDisable: false });
                break;
            case Constant.JOIN:
                this.setState({ isDisable: false });
                break;
            case Constant.CALL:
                this.setState({ isDisable: true });
                break;
            default:
                this.setState({ isDisable: true });
        }
    }

    handleDropClicked() {
        if (this.state.isDisable === true) {
            return;
        }
        this.setState({ isShow: !this.state.isShow });
    }

    changeUserStatus() {
        this.setState({ isShow: false });
        this.props.changeUserStatus();
    }

    render() {
        var userStatusName, userColor, dropStatusName, dropColor;
        switch (this.state.user_status) {
            case Constant.LOGOUT:
                userStatusName = Str.STR_OFF_LINE;
                userColor = UserColor.BROWN_COLOR;
                break;
            case Constant.AWAY:
                userStatusName = Str.STR_AWAY;
                userColor = UserColor.YELLOW_COLOR;
                dropStatusName = Str.STR_ON_LINE;
                dropColor = UserColor.GREEN_COLOR;
                break;
            case Constant.JOIN:
                userStatusName = Str.STR_ON_LINE;
                userColor = UserColor.GREEN_COLOR;
                dropStatusName = Str.STR_AWAY;
                dropColor = UserColor.YELLOW_COLOR;
                break;
            case Constant.CALL:
                userStatusName = Str.STR_ON_CALL;
                userColor = UserColor.PINK_COLOR;
                break;
            default:
                userStatusName = Str.STR_OFF_LINE;
                userColor = UserColor.BROWN_COLOR;
        }

        return (
            <div className='local_reception_area'>
                {/* <label className='reception_name' id='local_reception_name'>{this.state.user_name}</label> */}
                <div className='reception_status_area'>
                    <label className='reception_status_lab' style={{ backgroundColor: userColor, color: userStatusName === Str.STR_ON_LINE ? 'black' : 'white' }}>
                        {userStatusName}
                    </label>
                    <div className='reception_status_div'>
                        <ArrowDropDownIcon className='drop_icon' color={this.state.isDisable ? "disabled" : "action"} onClick={this.handleDropClicked} />
                        {
                            this.state.isDisable === true || this.state.isShow === false ?
                                null
                                :
                                <label className='reception_status_lab' id='drop_down_lab'
                                    style={{ backgroundColor: dropColor, cursor: 'pointer', color: dropStatusName === Str.STR_ON_LINE ? 'black' : 'white' }}
                                    onClick={this.changeUserStatus}
                                >
                                    {dropStatusName}
                                </label>
                        }
                        {/* <CallTimeLabel fontSize={'1'} duringTime={0} play={true} /> */}
                    </div>
                </div>
            </div>
        );
    }
}