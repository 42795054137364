const storages = {
    SESSION_IS_STARTED: "lucas_therapies_is_started",
    LOCAL_USER_NAME: "lucas_therapies_username",
    LOCAL_ROOM_NAME: "lucas_therapies_roomname",
    LOCAL_LOCATION_NAME: "lucas_therapies_locationname",
    LOCAL_COMPANY_NAME: "lucas_therapies_companyname",
    LOCAL_PWD: "lucas_therapies_password",
    LOCAL_IS_REMEMBER: "lucas_therapies_is_remember",
    LOCAL_IS_RDP: "lucas_therapies_is_rdp",
    LOCAL_CAMERA_ID: "lucas_therapies_camera_id",
    LOCAL_MIC_ID: "lucas_therapies_mic_id",
    LOCAL_LOCATION_QUERIES: "lucas_therapies_location_queries",
    LOCAL_APP_VERSION: "lucas_therapies_version",
    LOCAL_ROLE: "lucas_therapies_role",
    LOCAL_LOCATION_ID: "lucas_therapies_location_id",
    LOCAL_LOGINED_USER: "lucas_therapies_logined_username",
    LOCAL_TOKEN_USER: "lucas_therapies_token_user",
    LOCAL_KIOSK_USER: "lucas_therapies_kiosk_location",
    LOCAL_RECEPTION_NAME: "lucas_therapies_receptionname",
    LOCAL_ONTOP_MODE: "lucas_therapies_is_ontop_mode",
    LOCAL_SPEAKER_ID: "lucas_therapies_speaker_id",
    IS_RECEPTION_MANAGER: "lucas_therapies_reception_manager",
}

module.exports = storages