import React from "react";
import apiService from "./api"
import Storages from "../constants/storages"
import defaultStrings from "../default-strings"
import tokenService from "./tokenService";

export function init() {
  if(!window.i18n) {
    window.i18n = {
      locales: {},
      langs: [],
      multiLang: false,
      getString,
    };
  }
}

export async function getLangsDicts() {
  try{
    let token = tokenService.get();
    if(!token) {
      return {code: 1, msg: "Not logged in"};
    }

    const langsDictsRes = await apiService.getLangsDicts();
    const langsDicts = langsDictsRes.data;
    if(langsDicts.code !== 0 || !langsDicts.data) {
      console.error(5091, `Error while loading languages, fall back to default language.`, langsDicts.msg)
      return {code: 5091, msg: "Error while loading languages, fall back to default language."}
    }

    window.i18n.defaultLang = langsDicts.data.defaultLang;
    window.i18n.currentLangCode = langsDicts.data.defaultLang;
    window.i18n.multiLang = langsDicts.data.langs.length > 1;
    window.i18n.langs = langsDicts.data.langs;
    for(let lang of langsDicts.data.langs) {
      window.i18n.locales[lang.code] = {};
      const langDicts = langsDicts.data.dicts.filter(item => item.code == lang.code);
      for(let langDict of langDicts) {
        window.i18n.locales[lang.code][langDict.key] = langDict.value;
      }
    }
    return {code: 0}
  } catch (err) {
    console.error(5092, err)
    return {code: 5092, msg: "Error while loading languages, fall back to default language."}
  }
}

function getString(key, variables={}) {
  const languageJson = window.i18n.locales[window.i18n.currentLangCode]
  let value;
  if(languageJson && languageJson[key]) {
    value = languageJson[key]
  } else {
    value = defaultStrings[key]
  }
  for(let varName in variables) {
    value = value.replace(new RegExp(`\\[\\[${varName}\\]\\]`, "ig"), variables[varName])
  }

  value = value.replace(new RegExp(`\\[\\[.*?\\]\\]\\s*`, "ig"), "");

  return value;
}