import React, { useEffect, useState, useRef } from "react";
import {ClickAwayListener,Grow,Paper,Popper,ButtonBase,Tooltip,Slider} from '@mui/material';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeMuteOutlinedIcon from '@mui/icons-material/VolumeMuteOutlined';
import VolumeDownOutlinedIcon from '@mui/icons-material/VolumeDownOutlined';
import apiService from '../../services/api';
import './styleReception.css'
export default React.memo(function LocationVolume (props){
    const anchorRef = useRef(null);
    const [open,setOpen]=useState(false)
    const [locationVolume,setLocationVolume]=useState(props.locationVolume)
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
      setLocationVolume(props.locationVolume)
    }, [props.locationVolume])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event, index) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleLocVol = (event, value) => {
        setLocationVolume(value);
        apiService.sendMessage({
            to: props.locationEngaged,
            event: "sendVolumeToLocation",
            msg: {volume: value}
        }).catch((err) => {
          console.error(err);
        })
    };
    return (
        <div>
            <Tooltip title="Location volume" placement="top">
                <ButtonBase  className='volume-button' onClick={handleToggle} aria-haspopup="true" ref={anchorRef}>
                    { locationVolume < 30 ? 
                        <VolumeMuteOutlinedIcon className='volume-icon' fontSize="large"/>
                      : locationVolume < 60 ?
                        <VolumeDownOutlinedIcon className='volume-icon' fontSize="large"/>
                      :
                        <VolumeUpOutlinedIcon className='volume-icon' fontSize="large"/>
                    }
                </ButtonBase>
            </Tooltip>
            <Popper open={open}  anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='top-start'>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div className='volume-container'>
                                    <Slider
                                        className='volume-slider'
                                        sx={{
                                            '& input[type="range"]': {
                                                WebkitAppearance: 'slider-vertical',
                                            },
                                        }}
                                        orientation="vertical"
                                        value={locationVolume}
                                        aria-label="Volume"
                                        valueLabelDisplay="auto"
                                        size="small"
                                        onChangeCommitted={handleLocVol}
                                    />
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
})
