import { createSlice } from '@reduxjs/toolkit';

export const micSlice = createSlice({
	name: 'mics',
	initialState: [],
	reducers: {
		addMic: (state, action) => {
			var {deviceId,label,selected}=action.payload
			let index=state.findIndex((m)=>m.deviceId===deviceId)
			if(index > -1){
				state[index].label=label
			}
			else {
				const mic = {
				id: state.length,
				deviceId: action.payload.deviceId,
				label: action.payload.label,
				selected: action.payload.selected,
				};
				state.push(mic);
			}
			return state
		},
		selectMic: (state, action) => {
			const {deviceId,index}=action.payload
			state.forEach((el) => {
				el.selected = (el.deviceId===deviceId)?true:false;
			});
			
			if(index  || index===0){
				const selectedIndex = state.findIndex((mic) => mic.id === action.payload.index);
				state[selectedIndex].selected = true;
			}
			return state
		},
		deleteMic: (state, action) => {
			var {label,deviceId}=action.payload
			if(deviceId)state=state.filter((mic) => mic.deviceId != deviceId);
			else if(label)state=state.filter((mic) => mic.label != label);
			state = state.map((mic, index) => {
				let item = {
					id: index,
					deviceId: mic.deviceId,
					label: mic.label,
					selected: mic.selected,
				}
				return item;
			});
			return state;
		},
		deleteMics: () => {
			return [];
		},
		updateDefaultMic:(state, action)=>{
			var {deviceId,label}=action.payload
			state =state.map((mic)=>{
				if(mic.deviceId === deviceId){
					mic.label=label
				}
				return mic
			})
		}
	},
});

export const { addMic, selectMic, deleteMic, deleteMics,updateDefaultMic } = micSlice.actions;

export default micSlice.reducer;
