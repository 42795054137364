import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error)
    console.error(error.stack)
    if (window.electron) {
      console.alert(errorInfo)
    }
  }
  render() {
    if (this.state.hasError) {
      return <h2 style={{width: "100vw", margin: "45vh auto 0 auto", textAlign: "center"}}>Something went wrong!</h2>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary
